import React, { useState } from "react";
import handshake from "../assets/images/Handshake.jpg";
import technology from "../assets/images/iStock-1160576467.jpg";
import strategy from "../assets/images/strategicplanning.jpg";

const tabs = [
  { name: "White Glove Service" },
  { name: "Technological Excellence" },
  { name: "Strategic Insights" },
];

const About = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="container font-roboto flex flex-col items-center h-auto transition-all duration-500">
      <div className="w-full p-4">
        <p className="text-start text-3xl font-bold mb-3">About</p>
        <p className="text-start text-lg font-medium mb-3">The Mi Maxx Experience</p>

        <div className="flex flex-1 flex-col mx-auto mt-10">
          <div className="flex justify-around border-b-2 border-gray-200 md:mx-10 lg:mx-24">
            {tabs.map((tab, index) => (
              <button
                key={index}
                className={`flex-1 py-2 px-4 text-center transition-all duration-300 ${
                  activeTab === index
                    ? "border-b-2 border-primary_green-light text-primary_green-light"
                    : "border-b-2 border-transparent text-primary_green-deep"
                }`}
                onClick={() => setActiveTab(index)}
              >
                {tab.name}
              </button>
            ))}
          </div>

          {activeTab === 0 && (
            <div className="p-4 pt-7 animate-fade-in">
              <p className="text-center text-primary_green-deep text-lg font-semibold">White Glove Service</p>
              <p className="text-center text-gray-400 text-base">
                At the heart of our practice lies a symphony of exceptional client support, pioneering research, and supreme data
                management. These pillars of excellence are the foundation of our bespoke platform renowned for its comprehensive coverage
                providing a seamless single point of contact for every stakeholder in our client's journey.
              </p>
              <div className="mt-8 flex flex-col lg:flex-row items-center">
                <img src={handshake} alt="Handshake" className="rounded-md w-full lg:w-2/5 lg:mr-5" loading="lazy" />
                <div className="p-4 mt-5 lg:mt-0 flex items-center text-lg leading-relaxed">
                  <p>
                  At Mi Maxx LLC, we envision more than just providing services or consulting in the intricate 
                  field of class action settlements. We aim to be your strategic partner, exceeding the usual 
                  expectations of a settlement recovery consultant. We combine empathy, expertise, commitment, 
                  and care to ensure every dollar owed is returned to your business. Our extensive experience 
                  isn't merely a feature—it's the cornerstone of your success. Since our incorporation in 
                  Knoxville in 2021, we are committed to achieve remarkable outcomes. Our team is helping hundreds 
                  of clients recover the thousands of dollars they are owed, all on a contingency basis. You only 
                  pay us if we successfully recover funds for your business.
                  </p>
                </div>
              </div>
            </div>
          )}

          {activeTab === 1 && (
            <div className="p-4 pt-7 animate-fade-in">
              <p className="text-center text-primary_green-deep text-lg font-semibold">Technological Excellence</p>
              <p className="text-center text-gray-400 text-base">
                At Mi Maxx LLC, we are committed to staying at the forefront of technological innovation. Our team continuously
                integrates the latest advancements to enhance our service offerings, ensuring that we deliver cutting-edge solutions tailored
                to meet the unique needs of our clients. From leveraging AI-driven analytics to deploying robust cybersecurity measures, we
                ensure that every project we undertake is executed with precision, efficiency, and reliability. Our dedication to
                technological excellence empowers us to streamline operations, drive growth, and provide unparalleled value to our customers.
              </p>
              <div className="mt-8 flex flex-col lg:flex-row items-center">
                <img src={technology} alt="Technology" className="rounded-md w-full lg:w-2/5 lg:mr-5" loading="lazy" />
                <div className="p-4 mt-5 lg:mt-0 flex items-center text-lg leading-relaxed">
                  <p>
                    Our relentless pursuit of technological excellence is evident in every aspect of our operations. We invest heavily in
                    research and development to identify and implement the most effective tools and methodologies. This proactive approach
                    enables us to anticipate industry trends and respond swiftly to the evolving demands of the market. By fostering a culture
                    of continuous improvement and innovation, Mi Maxx LLC not only meets but exceeds the expectations of our clients,
                    providing them with a competitive edge in an increasingly digital world.
                  </p>
                </div>
              </div>
            </div>
          )}

          {activeTab === 2 && (
            <div className="p-4 pt-7 animate-fade-in">
              <p className="text-center text-primary_green-deep text-lg font-semibold">Strategic Insights</p>
              <p className="text-center text-gray-400 text-base">
                At Mi Maxx LLC, we believe that strategic insights are the cornerstone of successful business outcomes. Our expert team
                excels in transforming data into actionable strategies, providing clients with the clarity and foresight needed to navigate
                complex business landscapes. We utilize a comprehensive approach that combines market analysis, industry trends, and bespoke
                consulting to deliver insights that drive informed decision-making and sustainable growth. Our commitment to strategic
                excellence ensures that we not only meet but anticipate our clients' needs, positioning them for long-term success.
              </p>
              <div className="mt-8 flex flex-col lg:flex-row items-center">
                <img src={strategy} alt="Strategy" className="rounded-md w-full lg:w-2/5 lg:mr-5" loading="lazy" />
                <div className="p-4 mt-5 lg:mt-0 flex flex-col lg:items-start text-lg leading-relaxed">
                  <p><b>Our Services:</b></p>
                  <ul className="list-disc list-inside">
                    <li>Consulting and Advisory: Providing expert guidance to optimize business processes and strategies.</li>
                    <li>Technology Solutions: Implementing cutting-edge technologies to enhance operational efficiency and innovation.</li>
                    <li>Cybersecurity: Ensuring robust protection against digital threats with advanced security measures.</li>
                    <li>Data Analytics: Harnessing the power of data to uncover insights and drive strategic decisions.</li>
                    <li>Project Management: Delivering projects on time and within budget with meticulous planning and execution.</li>
                  </ul>
                  <p className="mt-4"><b>Trust in Our Strategy:</b></p>
                  <p>
                    When you partner with Mi Maxx LLC, you can trust that things will go as planned. Our comprehensive approach to
                    strategy and execution is designed to ensure seamless implementation and measurable results. We prioritize clear
                    communication, meticulous planning, and rigorous quality control to deliver outcomes that align with your business goals.
                    With Mi Maxx LLC, you gain a reliable partner dedicated to your success, providing the strategic insights and
                    technological expertise needed to thrive in today’s competitive environment.
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default About;
