import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import Main from "../Components/Main";
import { clearUser } from "../Redux/Slices/userSlice";
import ClaimScreen from "../Screens/AuthenticatedScreens/ClaimScreen";
import ContactUs from "../Screens/AuthenticatedScreens/ContactUs";
import DashScreen from "../Screens/AuthenticatedScreens/DashScreen";
import Dashboard from "../Screens/AuthenticatedScreens/Dashboard";
import ErrorPage from "../Screens/UnauthenticatedScreens/ErrorPage";
import Login from "../Screens/UnauthenticatedScreens/Login";
import { isValidAccessToken } from "../api/adminLogin";
import AuthorizationForm from "../Components/AuthorizationForm";


const ProjectRoutes = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user);

  useEffect(() => {
    if(user?.loginToken){
      if (!isValidAccessToken(user?.loginToken)) {
        dispatch(clearUser())
      }
    } else {
      dispatch(clearUser())
    }
  },[location])
  
  return (
    <>
        {user?.status ?
          <Dashboard>
            <Routes>
              <Route path="/" element={<DashScreen />} />
              <Route path="/claim-requests" element={<ClaimScreen />} />
              <Route path="/contact-requests" element={<ContactUs />} />
              <Route path="/agreement-view" element={<AuthorizationForm />} />
              <Route path="*" element={<ErrorPage />} />
            </Routes>
          </Dashboard>
          :
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/admin-login" element={<Login />} />
            <Route path="/*" element={<ErrorPage />} />
          </Routes>
        }
      </>
  );
};

export default ProjectRoutes;
