import { v4 as uuidv4 } from 'uuid';

import { AWS_DYNAMO_DB_CLAIMS } from "../Constants/deploymentConfig";
import dynamoDb from "./aws-config";
import moment from 'moment-timezone';
moment.tz.setDefault('America/New_York');


export const fetchData = async () => {
  const params = {
    TableName: AWS_DYNAMO_DB_CLAIMS,
  };

  try {
    const data = await dynamoDb.scan(params).promise();
    return data.Items;
  } catch (error) {
    console.log("Error fetching data:", error);
    throw new Error("Error fetching data");
  }
};

export const getCount = async () => {
    const params = {
      TableName: AWS_DYNAMO_DB_CLAIMS,
      Select: 'COUNT'
    };
  
    try {
      const data = await dynamoDb.scan(params).promise();
      return data.Count;
    } catch (error) {
      console.error("Error getting count:", error);
      throw new Error("Error getting count");
    }
};
  
export const generatePrimaryKey = async () => {
  try {
      // Fetch current record count
      const countParams = {
          TableName: AWS_DYNAMO_DB_CLAIMS,
          Select: 'COUNT'
      };
      
      const countData = await dynamoDb.scan(countParams).promise();
      const recordCount = countData.Count;

      // Generate primary key using UUID, timestamp, and record count
      const uuid = uuidv4();
      const now = new Date();
      const formattedTimeStamp = moment(now).format('YYYY-MM-DD_HH-mm-ss');
      const primaryKey = `${uuid}-${formattedTimeStamp}${recordCount + 1}`;

      return primaryKey;
  } catch (error) {
      console.error("Error generating primary key:", error);
      throw new Error("Could not generate primary key");
  }
};