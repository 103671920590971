import React, { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import './SignaturePad.css';

const SignaturePad = ({ signatureData }) => {
    const sigCanvasRef = useRef(null);
    const [imageData, setImageData] = useState(null);
    
    const handleClear = () => {
        sigCanvasRef.current.clear();
        setImageData(null);
        if (signatureData) {
            signatureData(null)
        }
    };

    const handleSave = () => {
        const data = sigCanvasRef.current.toDataURL('image/png');
        setImageData(data);
        if (signatureData) {
            signatureData(data)
        }
    };


    return (
        <div className="mb-4 flex flex-col lg:flex-row lg:space-x-4">
            <div className='lg:w-1/2 flex flex-col items-start'>
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="signatureCanvas">
                    Signature<span className="text-red-500">*</span>
                </label>
                <SignatureCanvas
                    penColor='black'
                    canvasProps={{ width: 310, height: 114, className: 'sigCanvas mb-3' }}
                    ref={sigCanvasRef}
                />
                <div className="flex space-x-3">
                    <button className='bg-primary_green-deep text-white px-4 py-2 rounded hover:bg-red-500' type='button' onClick={handleClear}>Clear</button>
                    <button className='bg-primary_green-deep text-white px-4 py-2 rounded hover:bg-primary_green-light' type='button' onClick={handleSave}>Save</button>
                </div>
            </div>
            {imageData && (
                <div className='lg:w-1/2 flex justify-start mt-4 lg:mt-0'>
                    <img id="signature-image" src={imageData} alt="Signature Image" />
                </div>
            )}
        </div>
    );
};

export default SignaturePad;
