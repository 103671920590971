import React, { useEffect } from 'react'
import errorImage from '../../assets/images/error.gif';
import { useNavigate } from 'react-router-dom';


const ErrorPage = () => {

  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate('/')
    },4000)
  }, [])

  return (
    <>
      <div className="h-screen flex items-center justify-center w-screen font-roboto bg-gradient-to-r from-primary_green-deep to-primary_green-light">
        <div className="d-flex bg-white p-10 justify-content-center align-items-center rounded-md shadow-2xl mt-5 pt-5">
          <div className="d-flex justify-content-between align-items-center flex-column gap-4 pt-5 text-center">
            <h1 className="text-4xl font-bold ">404</h1>
            <h2 className="text-xl font-semibold">Page Not Found</h2>
            <h6 className="base-font-600 text-base-gray fs-32 text-center">Sorry, The page you are looking for does not exist .....</h6>
            <div className='flex flex-1 justify-center'>
              <img src={errorImage} alt="Error" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ErrorPage