import React from "react";

function Product(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="75"
      height="75"
      viewBox="0 0 512.000000 512.000000"
      className="text-primary_green-light"
      {...props}
    >
      <path
        d="M1361 4550c-646-312-1180-576-1188-586-11-15-13-266-13-1405 0-1306 1-1388 18-1407C197 1129 2533 0 2561 0c27 0 2362 1129 2381 1152 17 19 18 101 18 1408s-1 1389-17 1408c-23 26-2357 1152-2386 1151-12 0-550-256-1196-569zm2167-57c532-256 1015-490 1074-518l108-52-310-150-310-150-178 86c-97 48-578 280-1069 516-491 237-892 433-893 436 0 4 594 296 608 298 2 1 439-209 970-466zm-684-438c581-280 1056-513 1056-516s-95-51-211-107l-211-102-972 469c-534 258-1016 491-1070 517l-99 48 209 103c115 56 216 101 225 100s492-231 1073-512zm-563-318l1124-543 5-473c5-453 6-473 24-487 11-8 32-14 46-14 28 0 594 270 620 296 13 12 16 85 20 485l5 470 320 155c176 86 330 160 343 165l22 9V1245L3734 725c-593-286-1081-521-1085-523-5-2-9 571-9 1275v1278l261 125c143 69 266 132 274 140 7 8 17 27 20 42 6 22 1 33-23 58-17 16-37 30-46 30-8 0-136-58-285-130-149-71-275-130-280-130s-57 23-115 50c-58 28-116 50-127 50-54 0-89-52-68-102 9-23 34-39 120-80l109-52V201l-59 27c-32 15-520 250-1084 522L311 1245l-1 1278v1277l33-14c17-8 391-188 830-400 523-253 809-386 829-386 17 0 40 7 50 17 22 20 24 80 3 103-8 9-382 193-831 410-720 348-813 395-793 404 13 6 179 86 369 178s348 167 351 167c3 1 512-243 1130-542zm1679-740v-383l-200-97-200-96v387l1 387 192 92c106 51 195 93 200 93 4 0 7-172 7-383z"
        transform="matrix(.1 0 0 -.1 0 512)"
        fill="currentColor"
      />
      <path
        d="M502 2037c-27-28-28-68-4-95 26-31 679-342 717-342 36 0 60 22 70 61 5 21 0 33-22 57-36 38-668 342-710 342-18 0-38-9-51-23zM505 1685c-32-31-33-74-2-102 26-25 372-193 397-193 10 0 29 9 44 21 29 23 36 72 14 102-16 21-381 197-408 197-12 0-32-11-45-25z"
        transform="matrix(.1 0 0 -.1 0 512)"
        fill="currentColor"
      />
    </svg>
  );
}

export default Product;
