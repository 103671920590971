import AWS from 'aws-sdk';
import { AWS_REGION, AWS_ACCESS_KEY_ID, AWS_SECRET_ACCESS_KEY, AWS_S3_BUCKET_NAME } from '../Constants/deploymentConfig';

window.Buffer = window.Buffer || require("buffer").Buffer;
// ==== Live AWS Config ====
AWS.config.update({
  region: AWS_REGION,
  accessKeyId: AWS_ACCESS_KEY_ID,
  secretAccessKey: AWS_SECRET_ACCESS_KEY,
});

// === Bucket configuration ===
export const myBucket = new AWS.S3({
  params: { Bucket: AWS_S3_BUCKET_NAME },
  region: AWS_REGION,
});

const dynamoDb = new AWS.DynamoDB.DocumentClient();

export default dynamoDb;