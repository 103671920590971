import * as React from "react";

function Finance(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="75"
      height="75"
      viewBox="0 0 512.000000 512.000000"
      className="text-primary_green-light"
      {...props}
    >
      <path
        d="M4134 4700c-141-26-265-92-374-200-133-132-202-301-202-495 0-327 212-599 532-681 86-22 244-22 330 0 243 63 423 230 506 471 25 72 28 93 28 215s-3 143-29 219c-73 214-249 382-470 451-80 24-241 34-321 20zm267-166c94-28 171-74 240-143 105-105 160-236 161-381 1-156-48-275-157-386-108-110-238-164-393-164-241 0-459 167-527 403-26 89-22 229 8 316 60 170 189 294 367 352 72 24 226 25 301 3z"
        transform="matrix(.1 0 0 -.1 0 512)"
        fill="currentColor"
      />
      <path
        d="M4190 4415c0-42-2-46-40-65-67-34-118-136-106-212 8-48 45-109 84-136 16-12 63-37 104-56 80-36 98-55 98-105 0-67-108-91-225-51-32 11-60 19-62 17-1-2-6-30-9-63l-6-60 78-18c44-10 85-20 92-22s12-21 12-49v-45h110v48c0 46 1 48 47 72 131 69 152 242 41 335-18 15-66 43-108 62-96 44-110 57-110 104 0 29 6 42 26 58 30 24 60 26 134 10 28-6 53-9 55-7 2 1 6 28 10 60l7 56-56 12-56 11v89h-120v-45zM3428 2925c-218-173-401-315-408-315-6 0-239 95-518 211-351 146-513 208-528 205-26-7-1112-778-1132-804-6-9-12-30-12-48 0-42 42-78 81-70 15 3 264 174 554 382 336 240 533 374 543 371 9-2 241-97 514-211 274-113 509-206 523-206 16 0 102 62 272 197 136 108 322 255 413 326 91 72 173 139 183 150 25 28 22 79-6 105-51 48-54 45-479-293z"
        transform="matrix(.1 0 0 -.1 0 512)"
        fill="currentColor"
      />
      <path
        d="M3856 2859l-26-20V570h-270v748c0 749 0 749-21 768-20 18-42 19-410 19h-388l-23-23-23-23-3-744-3-745h-259v805c0 792 0 805-20 825-19 19-33 20-409 20h-390l-20-26c-21-27-21-33-21-825V570h-260v565c0 398-3 571-11 589-21 45-41 47-443 44l-378-3-19-24c-18-22-19-47-19-597V570H261c-100 0-192-5-207-10-57-22-72-83-29-125l24-25h5022l24 25c30 29 32 74 6 106-19 24-23 24-215 27l-196 4v2259l-25 24-24 25h-379c-369 0-380-1-406-21zm684-1214V570h-560v2150h560V1645zm-2260-325V570h-560v743c0 409 3 747 7 750 3 4 129 7 280 7h273v-750zm1120-60V570h-550v1380h550v-690zm-2250-170V570H590v1040h560v-520z"
        transform="matrix(.1 0 0 -.1 0 512)"
        fill="currentColor"
      />
    </svg>
  );
}

export default Finance;
