import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './Slices/index'; // Import the combined reducers from slices/index.js

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      // Ignore these action types for non-serializable values
      ignoredActions: ['persist/PERSIST'],
      // Ignore these field paths in all actions
      ignoredActionPaths: ['register', 'rehydrate'],
      // Ignore these paths in the state
      ignoredPaths: ['some.nested.path'],
    },
  }),
});

export const persistor = persistStore(store);

export default store;
