import React from 'react'
import ProfileDropdown from './ProfileDropdown'

const DashBoardNavbar = () => {
  return (
    <nav className="fixed top-0 w-screen z-10 h-14 bg-gray-550 flex items-center justify-center shadow-xl">
    <div className="flex w-screen mr-5 justify-end items-center">
      <div className="space-x-2">
        <ProfileDropdown />
      </div>
    </div>
  </nav>
  )
}

export default DashBoardNavbar