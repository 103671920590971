import React, { useState } from "react";
import recovery from "../assets/images/Recovery.jpg";
import visamaster from "../assets/images/VisaMaster.jpg";
import Finance from "./VectorIcon/Finance";
import Heart from "./VectorIcon/Heart";
import TestTube from "./VectorIcon/TestTube";
import Product from "./VectorIcon/Product";
import { processData } from "../Constants/ServicesData";

const cards = [
  {
    icon: <Finance />,
    title: "Financial",
    description:
      "We decode the intricacies of financial settlements, from mis-sold products to unfair interchange charges, ensuring your business recovers its equitable share.",
  },
  {
    icon: <Heart />,
    title: "Healthcare",
    description:
      "We navigate the labyrinth of insurance settlements, Medicare adjustments, and other healthcare-related compensations.",
  },
  {
    icon: <TestTube />,
    title: "Pharmaceutical",
    description:
      "We facilitate the recovery process for settlements due to pricing disputes, patent infringements, and other regulatory issues.",
  },
  {
    icon: <Product />,
    title: "Product",
    description:
      "We assist businesses in recouping losses due to defective products or misrepresented goods.",
  },
];

const visaMasterCardCards = [
  {
    icon: "⭐", // Change these to actual icons as needed
    title: "What is the Settlement about?",
    description:
      "The Settlement resolves claims that Visa, Mastercard, and banks violated antitrust laws through high interchange fees, harming merchants from 2004-2019. A $5.54B settlement fund is established.",
  },
  {
    icon: "🏆", // Change these to actual icons as needed
    title: "Who can make a claim?",
    description:
      "Most U.S. merchants who accepted Visa/Mastercard credit or debit cards between January 1, 2004 – January 25, 2019 are eligible, unless they are a Dismissed Plaintiff or Defendant.",
  },
  {
    icon: "📈", // Change these to actual icons as needed
    title: "Do I need a third-party to file?",
    description:
      "Not necessarily.* However, a third-party can make the process quicker and simpler and compile additional information to maximize your claim.",
  },
];

const OurServices = () => {
  const [contentManager, setContentManager] = useState({
    //settlementRecoveryToggle: true,
    visaMasterToggle: true,
  });

  const services = [
    {
      id: 113,
      title: "Visa Master Card Service",
      description:
        "Streamlined payment processing solutions for secure and efficient transactions worldwide",
      clickHandler: () => {
        setContentManager({
          settlementRecoveryToggle: false,
          visaMasterToggle: true,
        });
      },
    },
  ];

  return (
    <div className="container font-roboto">
      <h2 className="text-start text-3xl font-bold mb-3 ml-2">Our Services</h2>
      <div className="md:flex md:flex-row gap-4">
        {services.map((service, index) => (
          <div
            key={index}
            className="service-item rounded-[10px] ease-in-out transition-transform transform hover:scale-105 duration-300 hover:shadow-lg hover:shadow-primary_green-light cursor-pointer"
            onClick={service.clickHandler}
          >
            <h3 className="mb-3 text-lg text-primary_green-deep font-bold capitalize">
              {service.title}
            </h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>
      {contentManager.settlementRecoveryToggle && (
        <span>
          <div className="mt-5">
            <p className="text-start text-xl font-semibold ml-2">
              Expert Solutions for Maximizing Your Settlement Recovery
            </p>
            <div className="mt-8 flex flex-col lg:flex-row">
              <div className="p-2 pt-0 flex items-start text-lg ">
                <p>
                  At Mi Maxx LLC, we envision more than just providing
                  services or consulting in the intricate field of class action
                  settlements. We aim to be your strategic partner, exceeding
                  the usual expectations of a settlement recovery consultant.
                  Acting as your advocate and guide, we combine empathy,
                  expertise, commitment, and care to ensure every dollar owed is
                  returned to your business. Our extensive experience isn't
                  merely a feature —it's the cornerstone of your success. Since
                  our incorporation in Knoxville in 2008, we have empowered our
                  clients to achieve remarkable outcomes. Our team has helped
                  hundreds of clients recover the millions of dollars they are
                  owed, all on a contingency basis. You only pay us if we
                  successfully recover funds for your business.
                </p>
              </div>
              <img
                src={recovery}
                alt="Recovery"
                className="object-cover rounded-md w-full lg:w-1/2 mt-5 lg:mt-0"
              />
            </div>
          </div>
          <div className="mt-6">
            <div className="container mx-auto p-4">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                {cards.map((card, index) => (
                  <Card
                    key={index}
                    icon={card.icon}
                    title={card.title}
                    description={card.description}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="ml-2">
            <p className="mb-4">
              Each year, billions of dollars become available through
              settlement opportunities. These funds originate from judicial
              mandates that compel companies to compensate for their
              negligence or unlawful actions that have caused harm. At Mi Maxx
              LLC, we specialize in identifying and securing these funds,
              transforming legal mandates into financial remedies for those
              affected.
            </p>
            <p className="mb-4">
              At Mi Maxx LLC, we bring a modern approach to class action
              settlements, combining top-tier class action insights, business
              acumen, and technological innovation. Our team comprises
              industry-leading professionals who are dedicated to ensuring
              that your settlement recovery process is as seamless as it is
              successful. Our experts diligently monitor settlement
              announcements and legal proceedings to ensure that no
              opportunity is missed.
            </p>
            <p className="mb-4">
              We understand the intricacies of the claims process and work
              tirelessly to navigate the complexities on behalf of our
              clients. By leveraging our extensive knowledge and experience,
              we streamline the recovery process, ensuring that individuals
              and organizations receive the compensation they deserve. Our
              cutting-edge technology allows us to manage, and process claims
              efficiently, minimizing delays and maximizing recoveries.
            </p>
            <p className="mb-4">
              Whether it's class action settlements, consumer protection
              claims, or corporate misconduct cases, Mi Maxx LLC is
              dedicated to advocating for the rights of those impacted. We
              provide comprehensive support, from the initial identification
              of potential claims to the final disbursement of funds, ensuring
              a seamless and efficient recovery experience.
            </p>
            <p className="mb-4">
              Our commitment to innovation and excellence sets us apart. We
              utilize advanced data analytics and proprietary software to
              track and manage claims, providing our clients with real-time
              updates and transparent reporting. Our holistic approach ensures
              that every aspect of the settlement recovery process is handled
              with precision and care.
            </p>
            <p className="mb-4">
              Join the many who have benefited from our expertise. Let Mi Maxx
              LLC turn legal resolutions into tangible financial relief
              for you. With us, you can trust that your settlement recovery is
              in the hands of dedicated professionals who are as committed to
              your success as you are.
            </p>
          </div>
          <div className="mt-10 ml-2">
            <p className="text-start text-xl font-semibold mb-8">
              <strong>The Mi Maxx LLC Process</strong>
            </p>
            <div>
              {processData.map((item, index) => (
                <div key={index} className="mb-3">
                  <p className="font-bold text-primary_green-deep">
                    {item.subHeading}
                  </p>
                  {item?.length != 0 &&
                    item.paragraphs.map((paragraph, paraIndex) => (
                      <p key={paraIndex}>{paragraph}</p>
                    ))}
                </div>
              ))}
            </div>
          </div>
        </span>
      )}
      {contentManager.visaMasterToggle && (
        <span>
          <div className="mt-5">
            <p className="text-start text-xl font-semibold ml-2">
              Unlock Your Compensation with Visa/MasterCard Settlements
            </p>
            <div className="mt-8 flex flex-col lg:flex-row">
              <div className="p-2 pt-0 flex items-start text-lg ">
                <p>
                  <strong style={{ color: "navy" }}>
                    CLAIM YOUR SHARE OF THE $5.54 BILLION
                  </strong>
                  <br />
                  Are you a merchant who accepted Visa or MasterCard credit or
                  debit cards in the U.S. between January 1, 2004, and January
                  25, 2019? You might be entitled to a portion of the historic
                  $5.54 billion settlement. This compensation addresses the
                  unfairly high interchange fees that have affected countless
                  businesses like yours.
                  <br />
                  <br />
                  The settlement is designed to resolve claims that Visa,
                  MasterCard, and major banks engaged in anticompetitive
                  practices, imposing inflated fees on merchants. By filing a
                  claim, you can recover funds that are rightfully yours and
                  offset some of the financial burdens caused by these unfair
                  practices.
                  <br />
                  <br />
                  Mi Maxx LLC specializes in guiding businesses through
                  the claims process, ensuring that you receive the maximum
                  compensation possible. With our expertise and streamlined
                  approach, you can navigate the complexities of the settlement
                  effortlessly.
                </p>
              </div>
              <img
                src={visamaster}
                alt="Visa MasterCard"
                className="object-cover rounded-md w-full lg:w-1/2 mt-5 lg:mt-0"
              />
            </div>
          </div>
          <div className="mt-6">
            <div className="container mx-auto p-4">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {visaMasterCardCards.map((card, index) => (
                  <div
                    key={index}
                    className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transform hover:scale-105 transition duration-300"
                  >
                    <div className="text-4xl mb-4">{card.icon}</div>
                    <h3 className="text-xl font-semibold mb-2">
                      {card.title}
                    </h3>
                    <p>{card.description}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="ml-2 mt-10">
            <p className="text-start text-xl font-semibold mb-4">
              Simplified Claim Process
            </p>
            <p>
              Navigating the settlement claim process can be daunting, but with
              Mi Maxx LLC, it doesn't have to be. Our team of experts is
              here to simplify every step, from eligibility verification to
              documentation and final submission. We work diligently to ensure
              that your claim is accurate, comprehensive, and timely, maximizing
              your chances of receiving the compensation you deserve.
              <br />
              <br />
              Join the many merchants who have successfully claimed their share
              of the settlement with our support. Take advantage of this
              opportunity to recover funds and strengthen your business's
              financial health. Contact Mi Maxx LLC today to get started on
              your claim.
            </p>
          </div>
        </span>
      )}
    </div>
  );
};

const Card = ({ icon, title, description }) => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transform hover:scale-105 transition duration-300">
      <div className="text-4xl mb-4">{icon}</div>
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <p>{description}</p>
    </div>
  );
};

export default OurServices;
