import { AWS_S3_BUCKET_NAME } from "../Constants/deploymentConfig";
import { myBucket } from "./aws-config";
import moment from 'moment-timezone';
moment.tz.setDefault('America/New_York');

export const uploadFile = async (file) => {
    const keyStamp = moment().format('YYYY-MM-DD_HH-mm-ss-SSS');
    const fileName = `${keyStamp}_${file.name}`;
    const params = {
        Bucket: AWS_S3_BUCKET_NAME,
        Key: fileName,
        Body: file,
        ContentDisposition: 'inline',
        ContentType: file.type
    };
    try {
        const data = await myBucket.upload(params).promise();
        return data.key;
    } catch (error) {
        console.log("Error uploading file:", error);
        throw new Error("Error uploading file");
    }
}