import React from "react";
import ExpandableTextSection from "./ExpandableTextSection";
import { faqData } from "../Constants/FAQData";

const SettlementRecoveryData = ({customData = faqData}) => {
  return (
    <div className="bg-white shadow-md rounded-lg p-4">
      {
        customData && customData?.map((item, quesIndex) => (
        <ExpandableTextSection title={item?.question} key={quesIndex}>
            {item?.answer?.length != 0 && item?.answer.map((ans, ansIndex) => (
              <p key={ansIndex}>{ans}</p>
          ))}
        </ExpandableTextSection>
        ))
      }
    </div>
  );
};

export default SettlementRecoveryData;
