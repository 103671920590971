import React from 'react'
import developmentImage from '../../assets/images/what-is-people-development.png'

const DashScreen = () => {
  return (
    <div className='flex-1'>
      <span className='text-2xl font-semibold'>DashScreen</span>
      <div className='flex flex-1 flex-col justify-center items-center'>
        <img src={developmentImage} className='w-4/5'/>
        <label className='text-2xl mt-8 font-roboto'>Under Development</label>
      </div>
    </div>
  )
}

export default DashScreen