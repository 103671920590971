import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  status: false,
  loginToken: null,
  email: null,
  firstName: null,
  lastName: null,
  phone: null,
  address: null,
  city: null,
  state: null,
  zip: null,
  clientEIN: null,
  clientDBA: null,
  country: null,
  profilePic: null,
  multiple_locations: null,
  referral: null,
  title: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.status = true;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    clearUser: (state) => {
      return initialState; // Reset the state to initial state
    },
    updateUserProfile: (state, action) => {
      const { email, firstName, lastName, phone, address, city, state: userState, zip, clientEIN, 
        clientDBA, country, profilePic, multiple_locations, referral, title } = action.payload;
      if (email) state.email = email;
      if (firstName) state.firstName = firstName;
      if (lastName) state.lastName = lastName;
      if (phone) state.phone = phone;
      if (address) state.address = address;
      if (city) state.city = city;
      if (userState) state.state = userState;
      if (zip) state.zip = zip;
      if (clientEIN) state.clientEIN = clientEIN;
      if (clientDBA) state.clientDBA = clientDBA;
      if (country) state.country = country;
      if (profilePic) state.profilePic = profilePic;
      if (multiple_locations) state.multiple_locations = multiple_locations;
      if (referral) state.referral = referral;
      if (title) state.title = title;
    },
    setLoginToken: (state, action) => {
      state.loginToken = action.payload;
    },
  },
});

export const { setUser, setStatus, clearUser, updateUserProfile, setLoginToken } = userSlice.actions;

export default userSlice.reducer;