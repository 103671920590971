export const dataURLtoFile = (dataUrl, filename) => {
  const arr = dataUrl.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const phoneNumberFormatter = (data) => {
  let value = data.replace(/\D/g, ''); // Remove all non-digit characters

  // Check if the value starts with "1" (representing "+1" country code)
  if (!value.startsWith('1')) {
    value = '1' + value;
  }

  // Trim the value to 11 digits if it exceeds 11 (including country code)
  if (value.length > 11) {
    value = value.slice(0, 11);
  }

  // Remove the leading "1" for formatting purposes
  const countryCode = value[0];
  let localNumber = value.slice(1);

  // Apply formatting rules to the local number
  if (localNumber.length > 3 && localNumber.length <= 6) {
    localNumber = localNumber.replace(/(\d{3})(\d+)/, '$1-$2');
  } else if (localNumber.length > 6) {
    localNumber = localNumber.replace(/(\d{3})(\d{3})(\d+)/, '$1-$2-$3');
  }

  // Combine the country code and the formatted local number
  return `+${countryCode} ${localNumber}`;
};

export const extractPlainNumber = (formattedNumber) => {
  // Remove the country code prefix, dashes, and spaces
  let plainNumber = formattedNumber.replace(/[^\d]/g, ''); // Remove all non-digit characters
  if (plainNumber.startsWith('1')) {
    plainNumber = plainNumber.slice(1); // Remove the leading '1' if it exists
  }
  return plainNumber;
};


export async function getBase64ImageFromURL(url) {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Failed to fetch image');
    }
    const blob = await response.blob();
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve, reject) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
    });
  } catch (error) {
    console.error('Error fetching image:', error);
    throw error; // Rethrow the error for the caller to handle
  }
}
