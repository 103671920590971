import { LoginCurve, UserCirlceAdd } from 'iconsax-react';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearUser } from '../Redux/Slices/userSlice';
import LogoutModal from './Modal/LogoutModal';
import Toast from './Toast';

const ProfileDropdown = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [logoutModal, setLogoutModal] = useState(false);
    const [ToasterData, setToasterData] = useState({
        type: '',
        message: '',
        showToast: false,
    });


    const handleLogout = async () => {
        dispatch(clearUser())
        setTimeout(()=>{
            setLogoutModal(false)
            navigate('/')
        },500)
    };

    const handelModalPopUp = (data) => {
        setLogoutModal(data);
    }


    const handleTosterClose = () => {
        setToasterData(false)
    }

    return (
      <>
    <div className="flex justify-end items-center">
                <div className="lg:w-64 sm:w-26 flex justify-end items-center"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <div
                        className={`relative py-1 ${isOpen
                            ? 'border-red-500 transform transition duration-300'
                            : ''
                            }`}
                    >
                        <div className="flex justify-center items-center space-x-3 cursor-pointer duration-500 hover:scale-105 delay-75">
                            <div className="overflow-hidden">
                                <UserCirlceAdd size="28" className='text-primary_green-deep' />
                            </div>
                            <div className="dark:text-white text-md hidden md:block overflow-hidden">
                                <div className="cursor-pointer tennis-secondary-font text-primary_green-deep">{`Admin`}</div>
                            </div>
                        </div>
                        {isOpen && (
                            <div className="absolute w-40 px-5 py-3 pr-0 right-0 dark:bg-primary_green-deep bg-primary_green-deep rounded-lg shadow border dark:border-transparent mt-4 transform transition-transform duration-300 scale-100">
                                <ul className="space-y-3 dark:text-white tennis-secondary-font text-sm transform transition-transform duration-300 scale-100">
                                    {/* <li className="font-medium">
                                    <a href="#" className="flex items-center transform transition-colors duration-200 border-r-4 border-transparent hover:border-yellow-500">
                                        <div className="mr-3">
                                            <User size="22" className='text-white' />
                                        </div>
                                        Account
                                    </a>
                                </li>
                                <li className="font-medium">
                                    <a href="#" className="flex items-center transform transition-colors duration-200 border-r-4 border-transparent hover:border-yellow-500">
                                        <div className="mr-3">
                                            <Setting2 size="22" className='text-white' />
                                        </div>
                                        Setting
                                    </a>
                                </li>
                                <hr className="dark:border-gray-700" /> */}
                                    <li className="font-medium" onClick={() => { handelModalPopUp(true) }}>
                                        <div className="flex items-center transform transition-colors duration-200 border-r-4 border-transparent hover:border-red-500 text-white cursor-pointer">
                                            <div className="mr-3 text-red-600">
                                                <LoginCurve size="22" className='text-white' />
                                            </div>
                                            Logout
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Toast {...ToasterData} onClose={handleTosterClose} />
            <LogoutModal visible={logoutModal} handelLogout={handleLogout} handleModal={handelModalPopUp} />
        </>
  )
}

export default ProfileDropdown