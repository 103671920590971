import React from 'react';

const UploadDocument = ({ register, name, validation, error, label, w9 = false, className = "",showAsterisk = true  }) => {
  return (
    <div className={`mb-4 lg:w-1/2 ${className}`}>
      {w9 && <div className="mb-4">
          <p className="text-sm font-bold text-red-500 animate-bounce">
            You can download and fill the W9 form from <a href="https://www.irs.gov/pub/irs-pdf/fw9.pdf" className="text-blue-500 underline">here</a> and upload it back.
          </p>
        </div>}
      <label
        className="block text-gray-700 text-sm font-bold mb-2"
        htmlFor={name}
      >
        { label ? label : 'Upload Document'}
        {showAsterisk && <span className="text-red-500">*</span>}
      </label>
      <div>
        <input
          type="file"
          accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          capture="environment"
          name={name}
          id={name}
          {...register(name, validation)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          //multiple
        />
        {error && <label className="text-red-400 text-sm">{error.message}</label>}
      </div>
    </div>
  );
};

export default UploadDocument;