export const processData = [
  {
    subHeading: "What to Expect from Us",
    paragraphs: [
      "The journey from identifying settlement opportunities to securing your rightful claim is intricate and demanding. It requires a dedication that could divert your focus from the vital operations that nurture and expand your business.",
      "Our clients trust us to have their vigilant eyes and strategic hands. Here's what you can expect from Mi Maxx LLC:",
    ],
  },
  {
    subHeading: "Scouring the Legal Landscape",
    paragraphs: [
      "We diligently monitor and research the legal realm to uncover settlement opportunities that align with your organization’s interests. Our team stays ahead of the curve, ensuring no potential claim goes unnoticed.",
    ],
  },
  {
    subHeading: "Navigating Documentation",
    paragraphs: [
      "Gathering necessary documents and information can often be a burdensome process. Our team stands ready to guide you through this, easing the documentation requirements and ensuring you have everything needed for a successful claim.",
    ],
  },
  {
    subHeading: "Crafting and Filing Claims",
    paragraphs: [
      "We meticulously prepare all required forms, ensuring your claims are submitted accurately and within the crucial deadlines. Our attention to detail and thorough approach minimizes the risk of errors and maximizes your chances of a successful recovery.",
    ],
  },
  {
    subHeading: "Ensuring Fair Distribution",
    paragraphs: [
      "By working closely with settlement administrators, we oversee the reconciliation and allocation of funds. We confirm that your business receives the appropriate share of any settlement, ensuring fair and equitable distribution.",
    ],
  },
  {
    subHeading: "",
    paragraphs: [
      "With Mi Maxx LLC, your journey from start to finish is in expert hands. We handle the complexities of the settlement recovery process, allowing you to stay focused on what you do best to grow your business. Trust us to transform legal resolutions into financial relief, making the intricate path to settlement recovery seamless and successful.",
    ],
  },
];
