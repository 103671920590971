import React from "react";

const TermsText = () => {
  return (
    <div className="text-left space-y-4 h-80 overflow-scroll overflow-y-auto border border-gray-600 p-10 rounded-[10px]">
      <p>
        <strong>CONSULTING FEE AGREEMENT</strong>
        <br />
        This Consulting Fee Agreement (the “Agreement”) is entered into as of
        the date the Merchant or Business submits its application for services
        at Mi Maxx LLC (“Effective Date”), by the Merchant or Business
        submitting its application for services at Mi Maxx LLC (“Client”), and
        Mi Maxx LLC. (“Consultant”).
      </p>
      <p>
        RECITALS WHEREAS, Client desires to engage Consultant exclusively to
        perform the Services, and Consultant desires to provide such Services to
        Client by the terms of, and subject to the limitations outlined in, this
        Agreement. NOW, THEREFORE, in consideration of the mutual promises and
        covenants outlined in the Agreement, and for other good and valuable
        consideration the receipt of which is acknowledged, the Client and
        Consultant agree as follows:
      </p>
      <p>
        <strong>INCORPORATION OF RECITALS</strong>
        <br />
        The introductory paragraph and the recitals set forth above are hereby
        incorporated by reference and made a part of this Agreement.
      </p>
      <p>1.GENERAL PROVISIONS</p>
      <ul className="list-disc list-inside">
        <br />
        <strong>a. Payment Card Settlement Disclaimer.</strong> Claim forms have
        been mailed and have been available online since December 2023. No-cost
        assistance is available from the Class Administrator and Class Counsel
        during the claims-filing period. No one is required to sign up with any
        third-party service in order to participate in any monetary relief. For
        additional information regarding the status of the settlement, visit the
        Court-approved website at{" "}
        <a href="www.paymentcardsettlement.com">
          www.paymentcardsettlement.com
        </a>
        .<p></p>
        <br />
        <strong>b. Electronic Signatures; Counterparts.</strong> Signatures
        transmitted by facsimile, email, “JPG,” “PDF,” or other electronic
        transmission shall be effective as original signatures. The Agreement
        may be executed in multiple counterparts, which, when taken together,
        shall be considered a single completely signed instrument. Submission of
        a completed Agreement to Consultant is represented by Signing The “Name”
        below, and this electronic registration will have the same force and
        effect as an original signature on a paper copy / hard copy of this
        Agreement.
        <p></p>
        <br />
        <strong>c. Authority.</strong> Each individual submitting Data on behalf
        of Client, whether individually or on behalf of any person or entity,
        warrants and represents that he, she, or it has full authority to so
        execute the Agreement on behalf of the parties on whose behalf he, she,
        or it so submits. Each separately acknowledges and represents that this
        representation and warranty is an essential and material provision of
        the Agreement and shall survive execution and/or termination of the
        Agreement. By Signing The “Name” below, I agree that I have the
        authority to register for this Service on behalf of the above-referenced
        entity previously defined as the Client, and I have read and agree to
        all sections of this Agreement.
        <p></p>
        <br />
        <strong>d. Governing Law.</strong> The Agreement is governed by and will
        be construed and enforced under the substantive and procedural laws of
        the State of Tennessee, without regard to conflict-of-laws principles.
        <p></p>
        <br />
        <strong>e. Dispute Resolution.</strong> The Parties agree that any
        controversy, claim, or dispute whatsoever between the Parties arising
        out of or in any way connected with or related to the Agreement or the
        relationship between the Parties, including, but not limited to, for the
        breach or threatened breach thereof, the termination thereof for any
        reason whatsoever, or regarding the validity thereof or any part
        thereof; claims for compensation; claims for breach of contract (express
        or implied); tort claims; or claims for the violation of any federal,
        state, or other government law, statute, regulation, ordinance, or rules
        (collectively, “Dispute”), shall be resolved through the use of binding
        arbitration with the American Arbitration Association (“AAA”) before one
        neutral arbitrator in Knox County, Tennessee. Such arbitration shall be
        conducted under the AAA’s Commercial Arbitration Rules; provided,
        however, that to the extent any injunctive relief, provisional remedy,
        or petition to compel arbitration is sought, the Parties agree and
        acknowledge that the Agreement is made, and shall be deemed to have been
        performed, only in Knox County, Tennessee, and that jurisdiction and
        venue for such purposes shall be solely and exclusively in the Superior
        Court of the State of Tennessee for Knox County (and not in any other
        court). The award rendered by the arbitrator or arbitrators shall be
        final, and judgment may be entered upon either Party according to
        applicable law in any court having jurisdiction thereof. The provisions
        of Tennessee Code of Civil Procedure sections 1283.05 are incorporated
        into this MSA and made a part of the Agreement. ANY ARBITRATION
        INVOLVING THE PARTIES WILL BE CONDUCTED IN THEIR INDIVIDUAL CAPACITIES
        ONLY AND NOT AS A CLASS ACTION OR REPRESENTATIVE ACTION, AND THE PARTIES
        EXPRESSLY WAIVE ANY RIGHTS TO FILE A CLASS ACTION OR REPRESENTATIVE
        ACTION OR SEEK RELIEF ON A CLASS BASIS OR REPRESENTATIVE BASIS
        CONCERNING ANY DISPUTE ARISING OUT OF OR RELATING TO THIS AGREEMENT. BY
        SIGNING THIS AGREEMENT, EACH PARTY AFFIRMS THAT IT UNDERSTANDS THAT BY
        AGREEING TO ARBITRATION TO EXCLUSIVELY RESOLVE DISPUTES AND CLAIMS, EACH
        IS GIVING UP ANY RIGHT TO A JUDGE OR JURY TRIAL WITH REGARD TO ALL
        DISPUTES (as defined herein). Notwithstanding the foregoing, the
        provisions of this Subparagraph 6.b do not apply to any Dispute in which
        the amount in controversy is within the jurisdiction of Tennessee small
        claims court. Such a Dispute shall be filed in the Small Claims Division
        of the Tennessee Superior Court in Knox County, Tennessee.
        <p></p>
        <br />
        <strong>f. Limitation of Damages in Disputes.</strong> Client
        acknowledges and agrees that Consultant, its personnel, its agents, and
        its assigns will not be held liable to Client or any other stakeholders
        for any costs, claims, or liabilities arising from, connected with, or
        in any way related to the Agreement for any individual or combined
        amount exceeding the amount Client paid to Consultant under the
        Agreement under any circumstances. Under no circumstances will
        Consultant, its shareholders, directors, officers, employees, agents,
        representatives, subcontractors, successors, or assigns be liable for
        indirect, nominal, or punitive damages arising from, connected with, or
        in any way related to the Agreement. The provisions of this Subparagraph
        will always apply to the greatest extent of any applicable laws.
        <p></p>
        <br />
        <strong>g. Advice of Counsel.</strong> In entering into the Agreement,
        each Party has relied upon the advice of its counsel or has been advised
        and has had reasonable time and opportunity to consult counsel of its
        choosing, regarding the content of the Agreement. Each Party represents
        and warrants that it has completely read, fully understands, and
        voluntarily accepts the terms of the Agreement.
        <p></p>
        <br />
        <strong>h. Successors and Assigns.</strong> Client shall not assign or
        transfer any rights, or delegate any duties, under the Agreement without
        Consultant’s prior written consent, and any attempted assignment,
        transfer, or delegation without such consent shall be voidable by
        Consultant. Notwithstanding the foregoing, in the event of any
        assignment, transfer, or delegation that is not voided by Consultant,
        the Agreement shall be fully binding upon Client’s transferees,
        assignees, and delegees. Consultant may assign the Agreement at any
        time.
        <p></p>
        <br />
        <strong>i. Severability.</strong>Whenever possible, each provision of
        the Agreement will be interpreted in such a manner as to be effective
        and valid under applicable law. If, however, any of the provisions
        contained in the Agreement are declared in a legal forum of competent
        jurisdiction to be fully or partially illegal, invalid, unlawful,
        unenforceable, or ineffective, the Parties agree that such part(s) or
        provision(s) shall be modified and reformed to achieve, to the maximum
        extent possible, the written intentions of the Parties herein. If
        modification of the fully or partially illegal, invalid, unlawful,
        unenforceable, or ineffective provision is not possible, such part(s) or
        provision(s) shall be deemed severable, such that the remainder of such
        part(s) or provision(s), and all other provisions contained in this
        Agreement, shall remain valid and binding if the essential terms and
        conditions of this Agreement for each Party remain valid, binding, and
        enforceable.
        <p></p>
        <br />
        <strong>j. Waiver.</strong> No waiver of any breach of this Agreement
        shall be construed to be, or shall constitute, a waiver of any other
        breach of this Agreement. No waiver shall be binding unless in writing
        and signed by the Party waiving the breach.
        <p></p>
        <br />
        <strong>k. Headings.</strong> Titles and paragraph headings used in the
        Agreement are for convenience of reference only, are not part of this
        Agreement, and shall not affect the construction of or be taken into
        consideration in interpreting this Agreement.
        <p></p>
        <br />
        <strong>l. Notices.</strong> For purposes of this Agreement, notices and
        all other communications required under this Agreement shall be in
        writing and shall be deemed to have been duly given when hand-delivered;
        sent by overnight courier; mailed by first-class, registered, or
        certified mail, return receipt requested, postage prepaid; or
        transmitted by email, in each case addressed as indicated on Client’s
        submitted application for the Services.
        <p></p>
        <br />
        <strong>m. Attorneys’ Fees.</strong> If any arbitration, action, or
        other legal proceeding (collectively, “Action”) is commenced by any
        Party arising under or out of, in connection with, in respect of, or
        relating to this Agreement, the prevailing Party in such Action shall be
        entitled to recover its reasonable costs, expenses, attorneys’ fees,
        expert fees, and court and/or arbitration costs/fees, incurred in the
        Action from the non-prevailing Party. Attorneys’ fees and other costs
        and fees incurred in connection with the enforcement of any judgment in
        respect of this Agreement are recoverable by the judgment creditor from
        the judgment debtor separately.
        <p></p>
        <br />
        <strong>n. Joint and Several Liability.</strong> If Client’s payment
        obligations under this Agreement are guaranteed by a guarantor
        (“Guarantor”), then Consultant shall have the right, but not the
        obligation, to proceed against Client and Guarantor regarding the
        enforcement of Consultant’s rights under this Agreement, in which case
        Client and Guarantor’s liability shall be joint and several.
        <p></p>
        <br />
        <strong>o. Cumulative Rights and Remedies.</strong> Cumulative Rights
        and Remedies. The rights and remedies provided for in this Agreement
        shall be cumulative; resorting to one right or remedy shall not preclude
        resorting to another or any other right or remedy provided for by law or
        in equity.
        <p></p>
        <br />
        <strong>p. Construction.</strong> Each Party has cooperated in the
        negotiation and preparation of the Agreement. Accordingly, the Agreement
        shall not be construed against any Party on the basis that the Party was
        the drafter.
        <p></p>
        <br />
        <strong>q. Entire Agreement.</strong> The Agreement constitutes the
        entire agreement and final understanding between the Parties concerning
        the subject of thereof and supersede all negotiations, representations,
        statements, promises, and agreements, whether existing, proposed, or
        otherwise, whether written or oral, concerning that subject, made on or
        before the Effective Date.
        <p></p>
        <br />
        <strong>r. Modification by Subsequent Agreement.</strong> Modification
        by Subsequent Agreement. This Agreement may be modified by subsequent
        agreement of the Parties only by an instrument in writing signed by the
        Parties.
        <p></p>
        <br />
        <strong>s. Disclaimer of Warranties.</strong> Consultant makes no
        warranties of any kind for the Services, whether express or implied,
        oral or written. Nothing in this Agreement, and nothing in Consultant’s
        statements to Client, shall be construed as a promise or guarantee about
        the outcome of the Services. There can be no assurance that Client will
        obtain any benefit in connection with the Services. Client acknowledges
        that Consultant has made no guarantee whatsoever regarding the
        successful completion of the Services or the outcome of this matter. Any
        expressions by Consultant regarding the likelihood of success or outcome
        are matters of opinion only, on which Client has not relied in any way
        or to any extent.
        <p></p>
        <br />
        <strong>t. Indemnification.</strong> Client agrees to indemnify and hold
        harmless Consultant and Consultant’s shareholders, directors, officers,
        employees, affiliates, agents, representatives, and subcontractors, from
        and against any and all claims, suits, actions, causes of actions,
        losses, damages, liabilities, costs, and expenses, including reasonable
        attorneys’ fees, arising out of or relating to :
        <p>
          <br />
          (i) the breach of any agreement, promise, representation, warranty, or
          covenant Client made in this Agreement;
          <br />
          (ii) the negligent or willful acts or omissionsof Client or any of
          Client’s shareholders, directors, officers, members, managers,
          employees, affiliates, agents, representatives, accountants, or
          subcontractors;
          <br />
          (iii) the performance or non‐performance in supplying the Data to
          Consultant in connection with the performance of the Services; or
          <br />
          (iv) the failure of Client to follow all applicable Settlement
          guidelines.
        </p>
        <br />
        <strong>u. Publicity.</strong> Unless Client has advised Consultant
        otherwise in writing, Client agrees that Consultant may use Client’s
        name and logo on Consultant’s website (including a link to the home page
        of Client’s website) and in Consultant’s marketing materials for the
        limited purpose of referring to Client as a client of Consultant.
        <p></p>
        <br />
        <strong>v. Tennessee Consumer Privacy Act Disclaimer.</strong>{" "}
        Consultant will not sell your personal information to anyone. If
        Consultant intends to change this policy, Consultant will provide Client
        with its opt-out rights required by law. You have the following rights
        with respect to your personal data: If you terminate this Agreement, you
        may request that Consultant destroy your personal information; you may
        request an overview, in a commonly used format, of the data Consultant
        processes about you; you may request correction or deletion of data if
        it is incorrect or no longer relevant.
        <p></p>
        <br />
        <strong>r. Modification by Subsequent Agreement.</strong> Modification
        by Subsequent Agreement. This Agreement may be modified by subsequent
        agreement of the Parties only by an instrument in writing signed by the
        Parties.
        <br />
      </ul>
      <p>2. CLIENT’S ADDITIONAL OBLIGATIONS AND ACKNOWLEDGEMENTS</p>
      <ul className="list-disc list-inside">
        Without limiting any other obligations of Client under the Agreement,
        Client consents to the following additional obligations: · Client shall
        submit to Consultant the necessary, complete, and accurate information,
        data, and documentation Consultant requires to provide the Services (the
        “Data”). Client understands that Consultant will not proceed with the
        Services until Client provides Consultant with all of the Data.
        Consultant is not responsible for any consequence to the Client
        concerning the Service unless Consultant receives the Data before the
        Settlement's claim filing deadline. · Client shall submit to Consultant
        all Data promptly after Consultant requests the Data. · Client shall
        promptly execute all documents and instruments that Consultant may
        request from time-to-time, relating to the Services. · Client shall
        promptly execute all documents and instruments that Consultant may
        request from time to time, granting Consultant rights to make inquiries
        and/or obtain information and documents from third-parties in
        performance of the Services concerning Client. · Client hereby
        authorizes Consultant to request, and First Data to provide, information
        for the preparation of Client’s claim. This information may include
        Client’s confidential information. First Data disclaims all warranties
        (express or implied) related to any data provided to Consultant. Client
        agrees that First Data is not a party to this Agreement and waives any
        right to take action, or assert any claim against, First Data with
        respect this Agreement. · Client shall not file or pursue a claim
        relating to the Settlement independently or with the assistance of any
        third party after the Effective Date of this Agreement. · If Client
        receives a written bona fide offer, including a bona fide letter of
        intent, to purchase all or substantially all of a claim relating to the
        Settlement (an “Offer”) and Client desires and elects to accept such
        Offer, Client shall notify Consultant of such Offer by submitting a
        written notice to sell or transfer the claim subject to the Offer to
        Consultant upon the terms and conditions (including the purchase price)
        outlined in such Offer and provide Consultant with a copy of such Offer
        and/or such other documents that explain the full terms and conditions
        of such Offer (the “Notice”). Upon receipt of the Notice, Consultant
        shall have thirty (30) days to exercise a right of first refusal to
        purchase the claim subject to such Offer at the price and upon the terms
        set forth in such Offer (the “ROFR”). During such thirty (30) day
        period, Client agrees to cooperate with all reasonable requests for
        information from Consultant. Without limiting the generality of the
        foregoing, Client agrees to provide any and all documents that
        Consultant reasonably requires to evaluate such Offer. In the event
        Consultant gives notice to Client that it is not exercising the ROFR or
        in the event Consultant fails to respond within such thirty (30) day
        period, the ROFR shall be null and void as to such Offer. In the event
        Consultant exercises the ROFR within such thirty (30) day period, it
        shall be bound by the terms of such Offer and shall enter into a
        mutually agreeable acquisition agreement for the subject claim. If so
        exercised, the closing on the purchase of the subject claim by
        Consultant (the “Closing”) shall be completed by the earlier of the date
        set forth in such Offer or sixty (60) days after Consultant notifies
        Client of its intention to exercise the ROFR (the “Closing Date”).
        Notwithstanding anything to the contrary in this paragraph, Consultant’s
        choice not to exercise the ROFR within such thirty (30) day period shall
        not prohibit Consultant from enforcing any other rights or taking any
        other actions authorized under this Agreement. · Any recovery Consultant
        procures for Client is reportable to the Internal Revenue Service
        (“IRS”) on Form 1099-MISC. Consultant is required by Federal law to
        withhold and pay to the IRS 24% of any recovery amount (“Backup
        Withholding”) if Client fails to provide Consultant with a valid
        Taxpayer Identification Number (“TIN”) on IRS Form W-9 or Consultant
        receives a CP2100 or CP2100A Notice from the IRS for Client. Client
        acknowledges Consultant’s obligation to conduct Backup Withholding and
        agrees that Consultant will be under no obligation to provide the
        Services if Client is subject to Backup Withholding.
      </ul>
      <p>3. INDEPENDENT CONTRACTOR RELATIONSHIP</p>
      <ul className="list-disc list-inside">
        Consultant shall at all times act strictly as an independent contractor.
        Nothing in the Agreement shall render Consultant an employee, partner,
        agent of, or joint venturer with Client for any purpose
      </ul>
      <p>4. OWNERSHIP OF METHOD</p>
      <ul className="list-disc list-inside">
        Consultant will not transfer to Client the legal title or physical
        possession of the method employed by Consultant in the provision of the
        Service (the “Method”). Consultant owns all rights, title, and interest,
        including but not limited to copyright, patent, trade secret, and all
        other intellectual property rights in the Method, and any changes,
        modifications, or corrections to the Method. If Client is ever held or
        deemed to be the owner of any copyrights in the Method or any changes,
        modifications, or corrections to the Method, Client hereby irrevocably
        assigns to Consultant, exclusively, all such rights, title, and
        interest. Client agrees to execute all documents necessary to implement
        and confirm the intent and letter of this Paragraph. Client agrees that
        the Method contains valuable trade secrets and confidential information
        owned by Consultant or third parties. Client represents and warrants
        that neither Client nor any of Client’s shareholders, directors,
        officers, members, managers, employees, affiliates, agents,
        representatives, and subcontractors will, directly or indirectly,
        without the express written permission of Consultant’s CEO:
        <p>
          <br />
          (i) sell, lease, license, sublicense, or otherwise transfer the
          Method;
          <br />
          (ii) duplicate, reproduce, or copy the Method;
          <br />
          (iii) disclose, divulge, or otherwise make available to any third
          party the Method;
          <br />
          (iv) decompile, disassemble, or otherwise analyze for reverse
          engineering purposes the Method; or
          <br />
          (v) use the Method for any purpose other than as necessary for the
          completion of Services by Consultant.
          <br />
        </p>
      </ul>
      <p>5. DATA VERIFICATION</p>
      <ul className="list-disc list-inside">
        Client shall provide Consultant with complete and correct Data as
        required for the Services. Client shall be fully and solely responsible
        for the accuracy of all Data provided or not provided. By submitting the
        Data, Client represents, warrants, and agrees that Client:
        <p>
          <br />
          (i) has reviewed and approved the Data, and
          <br />
          (ii) waives and releases any claim against Consultant arising out of
          any errors or omission in the Data that Client has not itself
          corrected or has not requested Consultant to correct. Client
          acknowledges that Consultant will rely upon the Data provided and
          agrees that Consultant will not be responsible for errors that result
          from its reliance on the Data. Client acknowledges that any request
          for corrections of Data after it is provide d to Consultant will be
          considered special handling and that an additional fee may be charged,
          in which case Client agrees to pay such additional fee to Consultant
          promptly.
          <br />
        </p>
      </ul>
      <p>6. TERMINATION BY CONSULTANT</p>
      <ul className="list-disc list-inside">
        In the event of a breach of the Agreement by Client, Consultant shall be
        entitled to immediately terminate the Agreement, without any obligation
        to perform further Services. In the event of such termination, any
        compensation owed to Consultant shall be paid immediately, regardless of
        when Consultant terminates the Agreement
      </ul>
      <p>7. EXCLUSIVE RIGHTS</p>
      <ul className="list-disc list-inside">
        Client grants Consultant the sole and exclusive right to provide the
        Services to Client relating to the Settlement and the exclusive right to
        earn the Consultant’s Fee, which exclusive rights shall begin on the
        Effective Date and end at 11:59:59 p.m. on the date that falls 30 months
        after the Effective Date (the “Exclusivity Period”)
      </ul>
      <p>8. CONSULTING SERVICES</p>
      <ul className="list-disc list-inside">
        Consultant shall use its professional expertise to provide Client with,
        and only with, the following services (the “Services”): · Utilizing
        Client’s information, Consultant will submit a claim in the class action
        settlement for the Visa MasterCard Class Action Settlement [In re
        Payment Card Interchange Fee and Merchant Discount Antitrust Litigation,
        MDL No. 1720] (the "Settlement"); · Consultant will compile Client’s
        claim utilizing the information provided by Client (the “Data”),
        Consultant’s aggregated data, and any information made available to
        Consultant by the Settlement Administrator. Consultant may also identify
        other merchant accounts within this data and include this data in
        Client’s claim. Once Client has provided all of the Data, Consultant
        will file a claim on Client’s behalf.
      </ul>
      <p>9. COMPENSATION</p>
      <ul className="list-disc list-inside">
        In consideration of the Services, and except as otherwise provided in
        this Paragraph, and in addition to any compensation required under this
        Agreement, Client agrees to compensate Consultant as follows: Consultant
        shall retain 30% of the gross amount of any and all recovery procured
        for Client’s claims (the “Consultant’s Fee”). Consultant's Fee includes
        all of Consultant’s expenses; no further expenses beyond Consultant’s
        Fee shall be due from Client unless Client is subject to backup
        withholding as outlined below. The recovered amounts Consultant procures
        for Client on account of Client’s claims will be deposited into
        Consultant’s account and Consultant will deduct its Fee, and withhold
        taxes if applicable, before distributing to Client Client's share.
        Consultant's Fee is contingent upon Client receiving proceeds. If Client
        receives no proceeds, for any reason, Consultant is not entitled to its
        Fee.
      </ul>
    </div>
  );
};

export default TermsText;
