import React, { useEffect, useState } from "react";
import image2 from "../assets/images/image2.jpg";
import "../index.css";
import About from "./About";
import ContactUs from "./ContactUs";
import FAQs from "./FAQs";
import Form from "./Form";
import Navbar from "./Navbar";
import OurServices from "./OurServices";

const Main = () => {
  const [hideDropdown, setHideDropdown] = useState({open: false});

  useEffect(() => {
    /**
     * This function is used to fetch the data from dynamoDB
     */
    //fetchData().then((resItem) => {
    //  console.log("Data fetched successfully",resItem); https://mimaxxservice.com
    //});
    //const createAndTestAdminUser = async () => {
    //  await addUser({ first_name: 'admin', last_name: 'admin', email: 'admin@mimaxxservice.com', password: 'admin@mimaxxservice.com', role: 'super_admin' }).then(() => console.log('user added')).catch((err) => console.log(err));
    //  //await validatePassword("admin@mimaxxservice.com", "admin@mimaxxservice.com").then((res) => console.log(res)).catch((err) => console.log(err));
    //}
    //createAndTestAdminUser();
  }, []);

  return (
    <>
      <Navbar hideDropdown={hideDropdown} />
      <div
        onClick={() => setHideDropdown({open: false})}
      >
        <section
          id="home"
          style={{ padding: "0px" }}
          className="animate-on-scroll font-roboto mt-16"
        >
          <div
            className="hero"
            style={{
              background: `url(${image2})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "55% center",
              backgroundSize: "cover",
            }}
          >
            <p className="text-black font-roboto font-bold">
              Welcome to Mi Maxx LLC
            </p>
            <h2 className="text-black text-5xl font-semibold mb-10">
              Your trusted partner for business solutions
            </h2>
            <a
              href="#services"
              className="bg-primary_green-deep hover:bg-primary_green-light py-3 px-5 rounded-full"
            >
              Our Services
            </a>
          </div>
        </section>
        <section id="about" className="animate-on-scroll">
          <About />
        </section>
        <section id="services" className="animate-on-scroll">
          <OurServices />
        </section>
        <section id="register" className="animate-on-scroll">
          <div className="container">
            <div className="font-roboto mx-2">
              <Form />
            </div>
          </div>
        </section>
        <section id="contact" className="animate-on-scroll">
          <ContactUs />
        </section>
        <section id="faqs" className="animate-on-scroll">
          <FAQs />
        </section>
      </div>
    </>
  );
};

export default Main;
