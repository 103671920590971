// Sidebar.js
import {
    ArrowCircleDown,
    DirectLeft,
    Home
} from "iconsax-react";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Menus } from "../Constants/constantData";
import logo from '../assets/images/logo.png'

const Sidebar = ({ open, setOpen, submenuOpen, handleSubmenuClick }) => {
    const pathname = useLocation();
    const navigate = useNavigate();
  return (
    <div
      className={`bg-primary_green-deep h-screen fixed left-0 top-0 bottom-0 duration-300 z-10 tennis-secondary-font pb-24 ${
        open ? "w-52" : "w-12"
      }`}
    >
      <div className="shadow-xl shadow-black">
        <DirectLeft
          variant="Bold"
          size={32}
          onClick={() => setOpen(!open)}
          className={`text-white bg-red-500 ease-in-out rounded-full p-2 fixed top-3 ${
            open ? "left-48" : "left-8"
          }  cursor-pointer shadow-xl ${!open && "rotate-180 duration-500"}`}
        />
      </div>
      <div
        className={`inline-flex w-full ${
          open ? "mt-5" : "mt-14"
        } mb-10 justify-center items-center`}
      >
        <div
          className={`p-1 bg-[#b7d433] rounded-full duration-500 ${
            open && "rotate-[360deg] ml-3"
          }`}
        >
                  {/*<Logo width={open ? 50 : 30} height={open ? 50 : 30} />*/}
                  <img src={logo} width={open ? 40 : 30} height={open ? 40 : 30} className="rounded-full"/>
        </div>

        {open && (
          <h1
            className={`ml-4 mt-1 text-white origin-left font-Roboto text-lg space-x-2 delay-75 uppercase duration-700 cursor-pointer hover:scale-110 `}
            onClick={() => {
                navigate("/");
            }}
          >
            Mi Maxx
          </h1>
        )}
      </div>
      <ul
        className={`sidebar-scroll scroll-smooth focus:scroll-auto h-[95%] overflow-y-auto`}
      >
        {Menus.map((menu, index) => (
          <div key={index}>
            {menu.submenu ? (
              <div
                onClick={(event) => handleSubmenuClick(index, event)}
                className={`text-white flex items-center ${
                  !open &&
                  menu.submenu &&
                  menu.submenuItems.find((item) => item.link === pathname)
                    ? "bg-teal-550 border-l-4 border-yellow-500"
                    : "bg-primary_green-deep"
                } gap-x-2 cursor-pointer p-2 hover:bg-teal-550 tennis-secondary-font ${
                  menu.spacing ? "mt-9" : "mt-2"
                } ${!open ? "pl-3" : "pl-2"}`}
              >
                <span className={`block float-left ${!open &&
                  menu.submenu &&
                  menu.submenuItems.find((item) => item.link === pathname) ? "-ml-1" : ""}  hover:text-black`}>
                  {menu.icon ? menu.icon : <Home />}
                </span>
                <span
                  className={`text-xs font-medium flex-1 duration-300 ${
                    !open && "scale-0"
                  }`}
                >
                  {menu.title}
                </span>
                {menu.submenu && open && (
                  <ArrowCircleDown
                    size="18"
                    className={`${
                      submenuOpen[index] && "rotate-180"
                    } duration-300`}
                  />
                )}
              </div>
            ) : (
              <a href={menu.link} key={index}>
                <div
                  className={`text-white text-sm flex items-center gap-x-2 cursor-pointer p-2 py-3 ${
                    menu?.link == pathname
                      ? "bg-primary_green-deep border-l-4 border-yellow-500"
                      : "bg-primary_green-deep"
                  } ${open ? "" : "bg-primary_green-deep"} hover:bg-primary_green-light ${
                    menu.spacing ? "mt-9" : "mt-0"
                  } ${!open ? "pl-3" : "pl-2"}`}
                >
                  <span className={`block float-left hover:text-black`}>
                    {menu.icon ? menu.icon : <Home />}
                  </span>
                  <span
                    className={`text-base font-medium flex-1 duration-300 hover:block ${
                      !open && "hidden"
                    }`}
                  >
                    {menu.title}
                  </span>
                  {menu.submenu && open && (
                    <ArrowCircleDown
                      size="18"
                      className={`${
                        submenuOpen[index] && "rotate-180"
                      } duration-300`}
                    />
                  )}
                </div>
              </a>
            )}

            {menu.submenu && submenuOpen[index] && open && (
              <ul className="transition-all duration-500 ease-in-out">
                {menu.submenuItems.map((submenuItem, subIndex) => (
                  <a href={`${submenuItem.link}`} key={subIndex}>
                      <li
                        className={`text-white text-xs items-center gap-x-2 cursor-pointer duration-500 my-2 pl-9 px-5 ${
                          submenuItem.link === pathname
                            ? "bg-teal-550 border-l-4 border-yellow-500"
                            : "bg-primary_green-deep"
                        } hover:bg-teal-550  hover:rounded-[5px] tennis-secondary-font p-2`}
                      >
                        <span className={`${submenuItem.link === pathname ? "-ml-1" : ""}`}>{submenuItem.title}</span>
                      </li>
                  </a>
                ))}
              </ul>
            )}
          </div>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
