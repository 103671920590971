import { useCallback, useEffect, useState } from 'react';
import dynamoDb from "./aws-config";
import { AWS_DYNAMO_DB_CLAIMS } from '../Constants/deploymentConfig';

const tableName = AWS_DYNAMO_DB_CLAIMS;

const useClaimPagination = (pageSize) => {
  const [items, setItems] = useState([]);
  const [pageKeys, setPageKeys] = useState([null]); // to track LastEvaluatedKey of each page
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isEndReached, setIsEndReached] = useState(false);

  const fetchData = useCallback(async (startKey = null) => {
    setLoading(true);
    setError(null);
    try {
      const params = {
        TableName: tableName,
        Limit: pageSize,
        ExclusiveStartKey: startKey,
      };
      const data = await dynamoDb.scan(params).promise();
      return { items: data.Items, lastKey: data.LastEvaluatedKey };
    } catch (err) {
      setError(err);
      return { items: [], lastKey: null };
    } finally {
      setLoading(false);
    }
  }, [pageSize]);

  const loadInitialData = useCallback(async () => {
    const { items: initialItems, lastKey } = await fetchData();
    setItems(initialItems);
    setPageKeys([null, lastKey]);
    setCurrentPageIndex(0);
    setIsEndReached(!lastKey);
  }, [fetchData]);

  useEffect(() => {
    loadInitialData();
  }, [loadInitialData]);

  const loadNext = async () => {
    if (!isEndReached) {
      const currentPageKey = pageKeys[currentPageIndex + 1];
      const { items: newItems, lastKey } = await fetchData(currentPageKey);
      setItems(newItems);
      setPageKeys(prevKeys => [...prevKeys.slice(0, currentPageIndex + 2), lastKey]);
      setCurrentPageIndex(prevIndex => prevIndex + 1);
      setIsEndReached(!lastKey);
    }
  };

  const loadPrevious = async () => {
    if (currentPageIndex > 0) {
      const previousPageKey = pageKeys[currentPageIndex - 1];
      const { items: previousItems } = await fetchData(previousPageKey);
      setItems(previousItems);
      setCurrentPageIndex(prevIndex => prevIndex - 1);
      setIsEndReached(false); // Hide the end message
    }
  };

  const resetPagination = async () => {
    await loadInitialData();
  };

  return { items, loading, error, loadNext, loadPrevious, isEndReached, isStart: currentPageIndex === 0, fetchData, resetPagination };
};

export default useClaimPagination;
