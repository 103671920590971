import React from 'react';
import {CloseCircle, TickCircle} from "iconsax-react"

const Toast = (props) => {
    return (
        <div onClick={props.onClose} className={`${props.showToast ? 'visible translate-x-0' : 'invisible translate-x-full'} fixed z-10 top-4 right-4 text-white transform transition-transform`}>
            <div className={`border-t-4  ${props.type === 'success' ? 'border-green-500' : 'border-red-500' } w-full`}>
                <div className="bg-white border-gray-300 border p-4 shadow-lg min-w-96">
                    <div className="flex items-center justify-start gap-4">
                        {props.type === 'success' && <TickCircle size="45" className="text-green-500"/>}
                        {props.type === 'failed' && <CloseCircle size="45" className="text-red-500"/>}
                       
                        <div className="">
                            <span className="font-semibold text-black capitalize mb-2 text-2xl tennis-primary-font-bold">{props.type}</span>
                            {props.message && (
                                <span className="block text-gray-800 text-sm capitalize tennis-secondary-font">{props.message}</span>
                            )}
                            {props.toastHeading && (
                                <span className="block text-gray-800 text-md capitalize tennis-primary-font-bold">{props.toastHeading}</span>
                            )}
                            {props.toastMessage && (
                                <span className="block text-gray-800 text-sm capitalize tennis-secondary-font">{props.toastMessage}</span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Toast;
