import React, { useEffect, useRef, useState } from "react";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import {
  getBase64ImageFromURL,
  phoneNumberFormatter,
} from "../Utility/fileUtilityFunction";
import moment from "moment-timezone";
import { AWS_MEDIA_URL } from "../Constants/deploymentConfig";
moment.tz.setDefault("America/New_York");

// Configure pdfMake with custom fonts
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const AuthorizationForm = () => {
  const totalFooters = 6;

  const componentRef = useRef();
  const [leftFooter, setLeftFooter] = useState("");
  const [businessDetails, setBusinessDetails] = useState([
    { label: "Submitted At", value: "" },
    { label: "Business Name", value: "" },
    { label: "Title", value: "" },
    { label: "First Name", value: "" },
    { label: "Last Name", value: "" },
    { label: "Annual Credit Sales", value: "$" + (250245).toFixed(2) },
    { label: "Tax Identification No. (EIN)", value: "" },
    { label: "Doing Business as (D/B/A)", value: "" },
    { label: "Multiple Location", value: "" },
    { label: "Referral Code", value: "" },
  ]);
  const [contactInformation, setContactInformation] = useState([
    { label: "Address", value: "" },
    { label: "State", value: "" },
    { label: "Zip", value: "" },
    { label: "Email", value: "" },
    { label: "Phone", value: "" },
  ]);

  const [imageURL, setImageURL] = useState("");

  const handlePrint = async () => {
    const input = componentRef.current;
    const pages = input.querySelectorAll(".page");

    const pdfContent = [];
    let imageBase64 = "";

    try {
      imageBase64 = await getBase64ImageFromURL(
        `${imageURL}?timestamp=${Date.now()}`
      );
    } catch (error) {
      console.error("Failed to convert image to base64:", error);
      // Handle the error here, e.g., show an error message to the user
    }

    pages.forEach((page, index) => {
      const textContent = Array.from(page.querySelectorAll("p")).map((p) => {
        const style = [];
        if (p.classList.contains("font-bold")) style.push("bold");
        if (p.classList.contains("italic")) style.push("italic");
        if (p.classList.contains("underline")) style.push("underline");
        if (p.classList.contains("ml-5")) style.push("ml-5");
        if (p.classList.contains("text-3xl")) style.push("text-3xl");
        if (p.classList.contains("mb-5")) style.push("mb-5");
        if (p.classList.contains("text-center")) style.push("text-center");
        if (p.classList.contains("text-xl")) style.push("text-xl");
        if (p.classList.contains("mr-5")) style.push("mr-5");
        if (p.classList.contains("inline")) style.push("inline");
        if (p.classList.contains("indent-6")) style.push("indent");
        if (!p.classList.contains("footer-text")) {
          return { text: p.innerText, style: style };
        }
        return {};
      });

      pdfContent.push(...textContent);
      pdfContent.push({ text: "", pageBreak: "after" });

      // Extract and style table data
      const tables = page.querySelectorAll("table");
      tables.forEach((table) => {
        const tableBody = [];
        const rows = table.querySelectorAll("tr");
        rows.forEach((row) => {
          const rowData = [];
          const cells = row.querySelectorAll("td, th");
          cells.forEach((cell) => {
            const cellStyle = [];
            if (cell.classList.contains("border")) cellStyle.push("border");
            if (cell.classList.contains("mb-5")) cellStyle.push("mb-5");
            if (cell.classList.contains("py-3")) cellStyle.push("py-3");
            if (cell.classList.contains("bg-slate-300"))
              cellStyle.push("bg-slate-300");
            if (cell.classList.contains("pl-4")) cellStyle.push("pl-4");
            if (cell.classList.contains("px-4")) cellStyle.push("px-4");
            if (cell.classList.contains("col-span-2"))
              cellStyle.push("col-span-2");
            if (cell.classList.contains("w-full")) cellStyle.push("w-full");

            rowData.push({ text: cell.innerText, style: cellStyle });
          });
          tableBody.push(rowData);
        });
        pdfContent.push({
          table: {
            widths: Array(rows[0].querySelectorAll("td, th").length).fill("*"), // Set all columns to have equal width
            body: tableBody,
          },
          layout: {
            fillColor: function (rowIndex, node, columnIndex) {
              return rowIndex === 0 ? "#e2e8f0" : null; // Applying background color to header row
            },
            hLineWidth: function (i, node) {
              return 0.5; // Adjust horizontal line width
            },
            vLineWidth: function (i, node) {
              return 0.5; // Adjust vertical line width
            },
            hLineColor: function (i, node) {
              return "#000000"; // Horizontal line color
            },
            vLineColor: function (i, node) {
              return "#000000"; // Vertical line color
            },
            paddingLeft: function (i, node) {
              return 4; // Padding left
            },
            paddingRight: function (i, node) {
              return 4; // Padding right
            },
            paddingTop: function (i, node) {
              return 4; // Padding top
            },
            paddingBottom: function (i, node) {
              return 4; // Padding bottom
            },
          },
        });
      });
    });

    pdfContent.push({
      image: imageBase64,
      width: 150, // Adjust the width as needed
      height: 75, // Adjust the height as needed
      alignment: "left",
      margin: [40, 20, 0, 0], // Adjust margins as needed
    });

    const docDefinition = {
      content: pdfContent,
      pageSize: "A4",
      pageMargins: [40, 60, 40, 60],
      footer: function (currentPage, pageCount) {
        // Define columns for justified layout
        const columns = [
          { text: leftFooter, alignment: "left" }, // Left-align left footer text
          {
            text: "MiMaxx | Page " + currentPage.toString() + " of " + pageCount,
            alignment: "right",
          }, // Right-align page number
        ];

        return {
          columns: columns,
          margin: [40, 20, 40, 20], // Adjust bottom margin to stick footer to the bottom
          fontSize: 10,
          style: "footer",
        };
      },
      styles: {
        footer: {
          fontSize: 10,
          italics: true,
        },
        bold: {
          bold: true,
        },
        italic: {
          italics: true,
        },
        underline: {
          decoration: "underline",
        },
        // Tailwind CSS based styles
        "text-center": {
          alignment: "center",
        },
        "text-base": {
          fontSize: 14,
        },
        block: {
          display: "block",
        },
        inline: {
          display: "inline",
        },
        "ml-5": {
          marginLeft: 20,
        },
        "text-xl": {
          fontSize: 20,
        },
        "text-3xl": {
          fontSize: 24,
          fontWeight: "bold",
          textAlign: "center",
        },
        "mb-5": {
          marginBottom: 20,
        },
        "mr-5": {
          marginRight: 20,
        },
        indent: {
          leadingIndent: 20,
        },
        // Table styles
        border: {
          border: [0.5, "solid", "#000000"],
        },
        "py-3": {
          marginTop: 8,
          marginBottom: 8,
        },
        "bg-slate-300": {
          fillColor: "#e2e8f0",
        },
        "pl-4": {
          marginLeft: 16,
        },
        "px-4": {
          marginLeft: 16,
          marginRight: 16,
        },
        "col-span-2": {
          colSpan: 2,
        },
        "w-full": {
          width: "100%",
        },
      },
    };
    pdfMake.createPdf(docDefinition).download("Authorization Form.pdf");
  };

  const leftFooterContent = leftFooter;
  const rightFooterContent = "Mi Maxx LLC |";

  useEffect(() => {
    // Retrieve data from localStorage
    const storedData = localStorage.getItem("documentData");

    // Check if data exists
    if (storedData) {
      // Parse the JSON string back into an object
      const data = JSON.parse(storedData);
      setBusinessDetails([
        {
          label: "Submitted At",
          value: moment(data?.created_at, "YYYY-MM-DD_HH:mm:ss").format(
            "DD-MMM-YYYY hh:mm A"
          ),
        },
        { label: "Business Name", value: data?.business_name },
        { label: "Title", value: data?.titel },
        { label: "First Name", value: data?.first_name },
        { label: "Last Name", value: data?.last_name },
        {
          label: "Annual Credit Sales",
          value: "$" + Number(data?.annual_credit_sales).toFixed(2),
        },
        { label: "Tax Identification No. (EIN)", value: data?.clientEIN },
        { label: "Doing Business as (D/B/A)", value: data?.clientDBA },
        { label: "Multiple Location", value: data?.multiple_locations },
        { label: "Referral Code", value: data?.referral },
      ]);

      setContactInformation([
        { label: "Address", value: data?.address },
        { label: "State", value: data?.state },
        { label: "Zip", value: data?.zip },
        { label: "Email", value: data?.email },
        { label: "Phone", value: phoneNumberFormatter(data?.phone) },
      ]);

      setLeftFooter(data?.first_name[0] + data?.last_name[0]);
      setImageURL(`${AWS_MEDIA_URL}${data?.signature}`);
      localStorage.removeItem("documentData");
    }
  }, []);

  const FooterComponent = ({ currentPage = 1, totalPage = totalFooters }) => {
    return (
      <div className="footer mt-4 border-t pt-4 flex flex-row justify-between">
        <p className="footer-text font-thin font-margarine text-sm">
          {leftFooterContent}
        </p>
        <p className="footer-text font-semibold font-cursive text-sm">{`${rightFooterContent} Page ${currentPage} of ${totalPage}`}</p>
      </div>
    );
  };

  const TableRows = ({ label, value }) => {
    return (
      <tr>
        <td className="border border-black px-4 py-2">{label}</td>
        <td className="border border-black px-4 py-2 col-span-2">{value}</td>
      </tr>
    );
  };

  return (
    <div>
      <div className="flex flex-row-reverse">
        <button
          onClick={handlePrint}
          className="btn bg-primary_green-deep mt-2"
        >
          Download PDF
        </button>
      </div>
      <div ref={componentRef} className="a4-size mx-auto my-8 p-8">
        {/* Page content */}
        <div className="page bg-white px-8 py-5">
          <div className="content">
            <p className="text-3xl mb-5 font-bold text-center">
              Authorization Letter
            </p>
            <p className="font-bold text-xl mb-5">Terms and Conditions</p>
            <p className="text-base font-bold mb-5">CONSULTING FEE AGREEMENT</p>
            <p className="mb-5 indent-6">
              This Consulting Fee Agreement (the "Agreement") is made effective
              as of the date the Merchant or Business submits its application
              for services to Mi Maxx LLC (the "Effective Date"). This
              Agreement is between the Merchant or Business submitting its
              application for services (the "Client") and Mi Maxx LLC
              (the "Consultant"). Client wishes to engage Consultant exclusively
              for the provision of Services, and Consultant agrees to deliver
              said Services to Client under the terms and conditions set forth
              in this Agreement. Therefore, in exchange for the mutual promises
              and commitments detailed herein, and for other valuable
              considerations acknowledged, Client and Consultant hereby agree as
              follows:
            </p>
            <p className="text-base mb-5">INCORPORATION OF RECITALS</p>
            <p className="block mb-5 ml-5 indent-6">
              The introductory paragraph and the recitals set forth above are
              hereby incorporated by reference and made a part of this
              Agreement.
            </p>
            <p className="block mb-5 ml-5">1.GENERAL PROVISIONS</p>
            <p className="block mb-5 ml-5 indent-6">
              a. Payment Card Settlement Disclaimer. Claim forms have been
              mailed and have been available online since December 2023. No-cost
              assistance is available from the Class Administrator and Class
              Counsel during the claims-filing period. No one is required to
              sign up with any third-party service in order to participate in
              any monetary relief. For additional information regarding the
              status of the settlement, visit the Court-approved website at
              www.paymentcardsettlement.com
            </p>
            <p className="block mb-5 ml-5 indent-6">
              b. Electronic Signatures; Counterparts. Signatures transmitted by
              facsimile, email, “JPG,” “PDF,” or other electronic transmission
              shall be effective as original signatures. The Agreement may be
              executed in multiple counterparts, which, when taken together,
              shall be considered a single completely signed instrument.
              Submission of a completed Agreement to Consultant is represented
              by Signing The “Name” below, and this electronic registration will
              have the same force and effect as an original signature on a paper
              copy / hard copy of this Agreement.
            </p>
            <p className="block mb-5 ml-5 indent-6">
              c. Authority. Each person submitting Data on behalf of the Client,
              whether individually or representing any other entity, warrants
              and affirms that they possess full authority to execute the
              Agreement on behalf of the relevant parties. Each acknowledges and
              affirms that this representation and warranty is a crucial and
              material provision of the Agreement, which remains valid even
              after the Agreement is executed or terminated. By signing below, I
              affirm that I am authorized to register for this Service on behalf
              of the Client defined above, and I have reviewed and consent to
              all sections of this Agreement.
            </p>
          </div>
          <FooterComponent currentPage={1} />
        </div>
        <div className="page mt-8 bg-white px-8 py-5">
          <div className="content">
            <p className="block mb-5 ml-5 indent-6">
              d. Governing Law. The Agreement is governed by and will be
              construed and enforced under the substantive and procedural laws
              of the State of Tennessee, without regard to conflict-of-laws
              principles.
            </p>
            <p className="block mb-5 ml-5 indent-6">
              e. Dispute Resolution. The Parties agree that any dispute,
              controversy, or claim arising out of or relating to the Agreement
              or the Parties' relationship, including, but not limited to,
              breaches, termination, validity, compensation claims, breach of
              contract (whether express or implied), tort claims, or violations
              of federal, state, or other governmental laws, statutes,
              regulations, ordinances, or rules (collectively referred to as
              "Dispute"), shall be resolved through binding arbitration.
              Administered by the American Arbitration Association ("AAA")
              before a neutral arbitrator in Knox County, Tennessee. The
              arbitration shall proceed under the AAA's Commercial Arbitration
              Rules. However, if injunctive relief, provisional remedies, or
              petitions to compel arbitration are sought, the Parties
              acknowledge that the Agreement is executed and deemed performed
              exclusively in Knox County, Tennessee. Jurisdiction and venue for
              such matters shall be solely and exclusively in the Superior Court
              of the State of Tennessee for Knox County.
            </p>
            <p className="block mb-5 ml-5 indent-6">
              The arbitrator's award shall be final, and judgment may be entered
              in any court having jurisdiction under applicable law. The
              provisions of Tennessee Code of Civil Procedure section 1283.05
              are incorporated into and form a part of this Agreement. Any
              arbitration involving the Parties shall be conducted in their
              individual capacities only, and not as a class action or
              representative action. The Parties expressly waive any rights to
              file or participate in a class action or representative action
              regarding any Dispute arising from or relating to this Agreement.
              By signing this Agreement, each Party acknowledges that by
              agreeing to arbitration as the exclusive method for resolving
              Disputes and claims, they waive their right to a trial by judge or
              jury for all such Disputes as defined herein. However, this
              provision does not apply to any Dispute within the jurisdiction of
              Tennessee's small claims court, which shall be filed in the Small
              Claims Division of the Tennessee Superior Court in Knox County,
              Tennessee.
            </p>
            <p className="block mb-5 ml-5 indent-6">
              f. Limitation of Damages in Disputes.Client acknowledges and
              agrees that Consultant, its personnel, agents, and assigns shall
              not be held liable to Client or any other stakeholders for costs,
              claims, or liabilities arising from or related to the Agreement,
              whether individually or in aggregate, exceeding the total amount
              paid by Client to Consultant under the Agreement. Consultant,
              including its shareholders, directors, officers, employees,
              agents, representatives, subcontractors, successors, and assigns,
              shall not be liable under any circumstances for indirect,
              incidental, or punitive damages arising from or connected with the
              Agreement. These provisions shall apply to the fullest extent
              permitted by applicable law.
            </p>
            <p className="block mb-5 ml-5 indent-6">
              g. Advice of Counsel.Upon entering into the Agreement, each Party
              has either relied on the advice of its legal counsel or has been
              adequately advised and provided reasonable time and opportunity to
              consult with counsel of its own choosing regarding the Agreement's
              contents. Each Party represents and warrants that they have
              thoroughly read, comprehended, and voluntarily agreed to the terms
              of the Agreement.
            </p>
          </div>
          <FooterComponent currentPage={2} />
        </div>
        <div className="page mt-8 bg-white px-8 py-5">
          <div className="content">
            <p className="block mb-5 ml-5 indent-6">
              h. Successors and Assigns.Client shall not assign or transfer any
              rights, or delegate any duties, under the Agreement without
              Consultant’s prior written consent, and any attempted assignment,
              transfer, or delegation without such consent shall be voidable by
              Consultant. Notwithstanding the foregoing, in the event of any
              assignment, transfer, or delegation that is not voided by
              Consultant, the Agreement shall be fully binding upon Client’s
              transferees, assignees, and delegees. Consultant may assign the
              Agreement at any time.
            </p>
            <p className="block mb-5 ml-5 indent-6">
              i. Severability. Whenever possible, each provision of the
              Agreement will be interpreted in such a manner as to be effective
              and valid under applicable law. If, however, any of the provisions
              contained in the Agreement are declared in a legal forum of
              competent jurisdiction to be fully or partially illegal, invalid,
              unlawful, unenforceable, or ineffective, the Parties agree that
              such part(s) or provision(s) shall be modified and reformed to
              achieve, to the maximum extent possible, the written intentions of
              the Parties herein. If modification of the fully or partially
              illegal, invalid, unlawful, unenforceable, or ineffective
              provision is not possible, such part(s) or provision(s) shall be
              deemed severable, such that the remainder of such part(s) or
              provision(s), and all other provisions contained in this
              Agreement, shall remain valid and binding if the essential terms
              and conditions of this Agreement for each Party remain valid,
              binding, and enforceable.
            </p>
            <p className="block mb-5 ml-5 indent-6">
              j. Waiver.No waiver of any breach of this Agreement shall be
              construed to be, or shall constitute, a waiver of any other breach
              of this Agreement. No waiver shall be binding unless in writing
            </p>
            <p className="block mb-5 ml-5 indent-6">
              k. Headings.Titles and paragraph headings used in the Agreement
              are for convenience of reference only, are not part of this
              Agreement, and shall not affect the construction of or be taken
              into consideration in interpreting this Agreement.
            </p>
            <p className="block mb-5 ml-5 indent-6">
              l. Notices. For purposes of this Agreement, notices and all other
              communications required under this Agreement shall be in writing
              and shall be deemed to have been duly given when hand-delivered;
              sent by overnight courier; mailed by first-class, registered, or
              certified mail, return receipt requested, postage prepaid; or
              transmitted by email, in each case addressed as indicated on
              Client’s submitted application for the Services.
            </p>
            <p className="block mb-5 ml-5 indent-6">
              m. Attorneys’ Fees. If any arbitration, action, or other legal
              proceeding (collectively, “Action”) is commenced by any Party
              arising under or out of, in connection with, in respect of, or
              relating to this Agreement, the prevailing Party in such Action
              shall be entitled to recover its reasonable costs, expenses,
              attorneys’ fees, expert fees, and court and/or arbitration
              costs/fees, incurred in the Action from the non-prevailing Party.
              Attorneys’ fees and other costs and fees incurred in connection
              with the enforcement of any judgment in respect of this Agreement
              are recoverable by the judgment creditor from the judgment debtor
              separately.
            </p>
            <p className="block mb-5 ml-5 indent-6">
              n. Joint and Several Liability. If Client’s payment obligations
              under this Agreement are guaranteed by a guarantor (“Guarantor”),
              then Consultant shall have the right, but not the obligation, to
              proceed against Client and Guarantor regarding the enforcement of
              Consultant’s rights under this Agreement, in which case Client and
              Guarantor’s liability shall be joint and several.
            </p>
          </div>
          <FooterComponent currentPage={3} />
        </div>
        <div className="page mt-8 bg-white px-8 py-5">
          <div className="content">
            <p className="block mb-5 ml-5 indent-6">
              o. Cumulative Rights and Remedies. Cumulative Rights and Remedies.
              The rights and remedies provided for in this Agreement shall be
              cumulative; resorting to one right or remedy shall not preclude
              resorting to another or any other right or remedy provided for by
              law or in equity.
            </p>
            <p className="block mb-5 ml-5 indent-6">
              p. Construction. Each Party has cooperated in the negotiation and
              preparation of the Agreement. Accordingly, the Agreement shall not
              be construed against any Party on the basis that the Party was the
              drafter.
            </p>
            <p className="block mb-5 ml-5 indent-6">
              q. Entire Agreement. The Agreement constitutes the entire
              agreement and final understanding between the Parties concerning
              the subject of thereof and supersede all negotiations,
              representations, statements, promises, and agreements, whether
              existing, proposed, or otherwise, whether written or oral,
              concerning that subject, made on or before the Effective Date.
            </p>
            <p className="block mb-5 ml-5 indent-6">
              r. Modification by Subsequent Agreement.Modification by Subsequent
              Agreement. This Agreement may be modified by subsequent agreement
              of the Parties only by an instrument in writing signed by the
              Parties.
            </p>
            <p className="block mb-5 ml-5 indent-6">
              s. Disclaimer of Warranties. Consultant makes no warranties of any
              kind for the Services, whether express or implied, oral or
              written. Nothing in this Agreement, and nothing in Consultant’s
              statements to Client, shall be construed as a promise or guarantee
              about the outcome of the Services. There can be no assurance that
              Client will obtain any benefit in connection with the Services.
              Client acknowledges that Consultant has made no guarantee
              whatsoever regarding the successful completion of the Services or
              the outcome of this matter. Any expressions by Consultant
              regarding the likelihood of success or outcome are matters of
              opinion only, on which Client has not relied in any way or to any
              extent.
            </p>
            <p className="block mb-5 ml-5 indent-6">
              t. Indemnification. Client agrees to indemnify and hold harmless
              Consultant and Consultant’s shareholders, directors, officers,
              employees, affiliates, agents, representatives, and
              subcontractors, from and against any and all claims, suits,
              actions, causes of actions, losses, damages, liabilities, costs,
              and expenses, including reasonable attorneys’ fees, arising out of
              or relating to :
            </p>
            <p className="block mb-2 ml-5 indent-6">
              - Violations of any agreement, promise, representation, warranty,
              or covenant made by the Client in this Agreement.
            </p>
            <p className="block mb-2 ml-5 indent-6">
              - Negligent or willful acts or omissions by the Client, its
              shareholders, directors, officers, members, managers, employees,
              affiliates, agents, representatives, accountants, or
              subcontractors.
            </p>
            <p className="block mb-2 ml-5 indent-6">
              - Issues related to the supply or non-supply of data to the
              Consultant during the provision of services.
            </p>
            <p className="block mb-5 ml-5 indent-6">
              - Failure by the Client to adhere to all relevant settlement
              guidelines.
            </p>
            <p className="block mb-5 ml-5 indent-6">
              u. Publicity. Unless Client has advised Consultant otherwise in
              writing, Client agrees that Consultant may use Client’s name and
              logo on Consultant’s website (including a link to the home page of
              Client’s website) and in Consultant’s marketing materials for the
              limited purpose of referring to Client as a client of Consultant.
            </p>
            <p className="block mb-5 ml-5 indent-6">
              v. Tennessee Consumer Privacy Act Disclaimer. Consultant will not
              sell your personal information to anyone. If Consultant intends to
              change this policy, Consultant will provide Client with its
              opt-out rights required by law. You have the following rights with
              respect to your personal data: If you terminate this Agreement,
              you may request that Consultant destroy your personal information;
              you may request an overview, in a commonly used format, of the
              data Consultant processes about you; you may request correction or
              deletion of data if it is incorrect or no longer relevant.
            </p>
            <p className="block mb-5 ml-5 indent-6">
              r. Modification by Subsequent Agreement.Modification by Subsequent
              Agreement. This Agreement may be modified by subsequent agreement
              of the Parties only by an instrument in writing signed by the
              Parties.
            </p>
            <p className="block mb-5 ml-5">
              2. CLIENT’S ADDITIONAL OBLIGATIONS AND ACKNOWLEDGEMENTS
            </p>
            <p className="block mb-5 ml-5 indent-6">
              Without limiting any other obligations of Client under the
              Agreement, Client consents to the following additional
              obligations: · Client shall submit to Consultant the necessary,
              complete, and accurate information, data, and documentation
              Consultant requires to provide the Services (the “Data”). Client
              understands that Consultant will not proceed with the Services
              until Client provides Consultant with all of the Data. Consultant
              is not responsible for any consequence to the Client concerning
              the Service unless Consultant receives the Data before the
              Settlement's claim filing deadline. · Client shall submit to
              Consultant all Data promptly after Consultant requests the Data. ·
              Client shall promptly execute all documents and instruments that
              Consultant may request from time-to-time, relating to the
              Services. · Client shall promptly execute all documents and
              instruments that Consultant may request from time to time,
              granting Consultant rights to make inquiries and/or obtain
              information and documents from third-parties in performance of the
              Services concerning Client. · Client hereby authorizes Consultant
              to request, and First Data to provide, information for the
              preparation of Client’s claim. This information may include
              Client’s confidential information. First Data disclaims all
              warranties (express or implied) related to any data provided to
              Consultant. Client agrees that First Data is not a party to this
              Agreement and waives any right to take action, or assert any claim
              against, First Data with respect this Agreement. Client shall not
              file or pursue a claim relating to the Settlement independently or
              with the assistance of any third party after the Effective Date of
              this Agreement.
            </p>

            <p className="block mb-5 ml-5 indent-6">
              If Client receives a written bona fide offer, including a bona
              fide letter of intent, to purchase all or substantially all of a
              claim relating to the Settlement (an “Offer”) and Client desires
              and elects to accept such Offer, Client shall notify Consultant of
              such Offer by submitting a written notice to sell or transfer the
              claim subject to the Offer to Consultant upon the terms and
              conditions (including the purchase price) outlined in such Offer
              and provide Consultant with a copy of such Offer and/or such other
              documents that explain the full terms and conditions of such Offer
              (the “Notice”). Upon receipt of the Notice, Consultant shall have
              thirty (30) days to exercise a right of first refusal to purchase
              the claim subject to such Offer at the price and upon the terms
              set forth in such Offer (the “ROFR”). During such thirty (30) day
              period, Client agrees to cooperate with agrees to cooperate with
              all reasonable requests for information from Consultant. Without
              limiting the generality of the foregoing, Client agrees to provide
              any and all documents that Consultant reasonably requires to
              evaluate such Offer. In the event Consultant gives notice to
              Client that it is not exercising the ROFR or in the event
              Consultant fails to respond within such thirty (30) day period,
              the ROFR shall be null and void as to such Offer.
            </p>
          </div>
          <FooterComponent currentPage={4} />
        </div>
        <div className="page mt-8 bg-white px-8 py-5">
          <div className="content">
            <p className="block mb-5 ml-5 indent-6">
              In the event Consultant exercises the ROFR within such thirty (30)
              day period, it shall be bound by the terms of such Offer and shall
              enter into a mutually agreeable acquisition agreement for the
              subject claim. If so exercised, the closing on the purchase of the
              subject claim by Consultant (the “Closing”) shall be completed by
              the earlier of the date set forth in such Offer or sixty (60) days
              after Consultant notifies Client of its intention to exercise the
              ROFR (the “Closing Date”). Notwithstanding anything to the
              contrary in this paragraph, Consultant’s choice not to exercise
              the ROFR within such thirty (30) day period shall not prohibit
              Consultant from enforcing any other rights or taking any other
              actions authorized under this Agreement. · Any recovery Consultant
              procures for Client is reportable to the Internal Revenue Service
              (“IRS”) on Form 1099-MISC. Consultant is required by Federal law
              to withhold and pay to the IRS 24% of any recovery amount (“Backup
              Withholding”) if Client fails to provide Consultant with a valid
              Taxpayer Identification Number (“TIN”) on IRS Form W-9 or
              Consultant receives a CP2100 or CP2100A Notice from the IRS for
              Client. Client acknowledges Consultant’s obligation to conduct
              Backup Withholding and agrees that Consultant will be under no
              obligation to provide the Services if Client is subject to Backup
              Withholding.
            </p>
            <p className="block mb-5 ml-5">
              3. INDEPENDENT CONTRACTOR RELATIONSHIP
            </p>
            <p className="block mb-5 ml-5 indent-6">
              Consultant shall at all times act strictly as an independent
              contractor. Nothing in the Agreement shall render Consultant an
              employee, partner, agent of, or joint venturer with Client for any
              purpose
            </p>
            <p className="block mb-5 ml-5">4. OWNERSHIP OF METHOD</p>
            <p className="block mb-5 ml-5 indent-6">
              Consultant will not transfer to Client the legal title or physical
              possession of the method employed by Consultant in the provision
              of the Service (the “Method”). Consultant owns all rights, title,
              and interest, including but not limited to copyright, patent,
              trade secret, and all other intellectual property rights in the
              Method, and any changes, modifications, or corrections to the
              Method. If Client is ever held or deemed to be the owner of any
              copyrights in the Method or any changes, modifications, or
              corrections to the Method, Client hereby irrevocably assigns to
              Consultant, exclusively, all such rights, title, and interest.
              Client agrees to execute all documents necessary to implement and
              confirm the intent and letter of this Paragraph. Client agrees
              that the Method contains valuable trade secrets and confidential
              information owned by Consultant or third parties. Client
              represents and warrants that neither Client nor any of Client’s
              shareholders, directors, officers, members, managers, employees,
              affiliates, agents, representatives, and subcontractors will,
              directly or indirectly, without the express written permission of
              Consultant’s CEO:
            </p>
            <p className="block mb-5 ml-5 indent-6">
              (i) sell, lease, license, sublicense, or otherwise transfer the
              Method;
            </p>

            <p className="block mb-5 ml-5 indent-6">
              (ii) duplicate, reproduce, or copy the Method;
            </p>

            <p className="block mb-5 ml-5 indent-6">
              (iii) disclose, divulge, or otherwise make available to any third
              party the Method;
            </p>

            <p className="block mb-5 ml-5 indent-6">
              (iv) decompile, disassemble, or otherwise analyze for reverse
              engineering purposes the Method; or
            </p>

            <p className="block mb-5 ml-5 indent-6">
              (v) use the Method for any purpose other than as necessary for the
              completion of Services by Consultant.
            </p>
            <p className="block mb-5 ml-5">5. DATA VERIFICATION</p>
            <p className="block mb-5 ml-5 indent-6">
              Client shall provide Consultant with complete and correct Data as
              required for the Services. Client shall be fully and solely
              responsible for the accuracy of all Data provided or not provided.
              By submitting the Data, Client represents, warrants, and agrees
              that Client:
            </p>
            <p className="block mb-5 ml-5 indent-6">
              (i) has reviewed and approved the Data, and
            </p>

            <p className="block mb-5 ml-5 indent-6">
              (ii) Client waives and releases any claim against Consultant
              arising out of any errors or omission in the Data that Client has
              not itself corrected or has not requested Consultant to correct.
              Client acknowledges that Consultant will rely upon the Data
              provided and agrees that Consultant will not be responsible for
              errors that result from its reliance on the Data. Client
              acknowledges that any request for corrections of Data after it is
              provided to Consultant will be considered special handling and
              that an additional fee may be charged, in which case Client agrees
              to pay such additional fee to Consultant promptly.
            </p>

            <p className="block mb-5 ml-5">6. TERMINATION BY CONSULTANT</p>

            <p className="block mb-5 ml-5 indent-6">
              In the event of a breach of the Agreement by Client, Consultant
              shall be entitled to immediately terminate the Agreement, without
              any obligation to perform further Services. In the event of such
              termination, any compensation owed to Consultant shall be paid
              immediately, regardless of when Consultant terminates the
              Agreement
            </p>

            <p className="block mb-5 ml-5">7. EXCLUSIVE RIGHTS</p>
            <p className="block mb-5 ml-5">
              <p className="mr-5 inline"></p>Client grants Consultant the sole
              and exclusive right to provide the Services to Client relating to
              the Settlement and the exclusive right to earn the Consultant’s
              Fee. These exclusive rights commence on the Effective Date and end
              at 11:59:59 p.m. on the date that falls 30 months after the
              Effective Date (the “Exclusivity Period”).
            </p>
            <p className="block mb-5 ml-5">8. CONSULTING SERVICES</p>
            <p className="block mb-2 ml-5 indent-6">
              Consultant shall use its professional expertise to provide Client
              with, and only with, the following services (the “Services”):
            </p>
            <p className="block mb-2 ml-5 indent-6">
              - Utilizing Client’s information, Consultant will submit a claim
              in the class action settlement for the Visa MasterCard Class
              Action Settlement [In re Payment Card Interchange Fee and Merchant
              Discount Antitrust Litigation, MDL No. 1720] (the "Settlement").
            </p>
            <p className="block mb-5 ml-5 indent-6">
              - Consultant will compile Client’s claim utilizing the information
              provided by Client (the “Data”), Consultant’s aggregated data, and
              any information made available to Consultant by the Settlement
              Administrator. Consultant may also identify other merchant
              accounts within this data and include this data in Client’s claim.
              Once Client has provided all of the Data, Consultant will file a
              claim on Client’s behalf.
            </p>
          </div>
          <FooterComponent currentPage={5} />
        </div>
        <div className="page mt-8 bg-white px-8 py-5">
          <div className="content">
            <p className="block mb-5 ml-5">9. COMPENSATION</p>
            <p className="block mb-5 ml-5 indent-6">
              In consideration of the Services, and except as otherwise provided
              in this Paragraph, and in addition to any compensation required
              under this Agreement, Client agrees to compensate Consultant as
              follows: Consultant shall retain 30% of the gross amount of any
              and all recovery procured for Client’s claims (the “Consultant’s
              Fee”). Consultant's Fee includes all of Consultant’s expenses; no
              further expenses beyond Consultant’s Fee shall be due from Client
              unless Client is subject to backup withholding as outlined below.
              The recovered amounts Consultant procures for Client on account of
              Client’s claims will be deposited into Consultant’s account and
              Consultant will deduct its Fee, and withhold taxes if applicable,
              before distributing to Client Client's share. Consultant's Fee is
              contingent upon Client receiving proceeds. If Client receives no
              proceeds, for any reason, Consultant is not entitled to its Fee.
            </p>
            <div id="html-table-data w-full">
              <table className="table mb-5 w-full">
                <tr className="text-left">
                  <td className="border border-black py-3 bg-slate-300 pl-4">
                    Fields
                  </td>
                  <td className="border border-black py-3 bg-slate-300 pl-4">
                    Details
                  </td>
                </tr>
                {businessDetails &&
                  businessDetails?.length > 0 &&
                  businessDetails?.map((item, index) => (
                    <TableRows
                      key={index}
                      label={item?.label}
                      value={item?.value}
                    />
                  ))}
              </table>
              <table className="table w-full">
                <tr className="text-left">
                  <td className="border border-black py-3 bg-slate-300 pl-4">
                    Fields
                  </td>
                  <td className="border border-black py-3 bg-slate-300 pl-4">
                    Details
                  </td>
                </tr>
                {contactInformation &&
                  contactInformation?.length > 0 &&
                  contactInformation?.map((item, index) => (
                    <TableRows
                      key={index}
                      label={item?.label}
                      value={item?.value}
                    />
                  ))}
              </table>
              <img src={imageURL} className="h-[114] w-[310]" />
            </div>
          </div>
          <FooterComponent currentPage={6} />
        </div>
      </div>
    </div>
  );
};

export default AuthorizationForm;
