import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from "react-router-dom";
import store, { persistor } from './Redux/store';
import ProjectRoutes from './Router/ProjectRoutes';

const App = () => {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ProjectRoutes />
        </PersistGate>
      </Provider>
    </BrowserRouter>
  );
}

export default App;