import React, { useState } from "react";
import faqImage from "../assets/images/FAQs.jpg";
import SettlementRecoveryData from "./SettlementRecoveryData";
import { faqData, faqVisaData } from "../Constants/FAQData";

const tabs = [
 // { name: "Settlement Recovery Services" },
  { name: "Visa/Mastercard Settlement" },
];

const FAQs = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="container font-roboto flex flex-col items-center h-auto">
      <div className="text-container flex-1 w-full p-4 pb-0">
        <h2 className="text-start text-3xl font-bold">FAQs</h2>
      </div>
      <div className="flex flex-col lg:flex-row space-y-10">
        <img
          src={faqImage}
          alt="FAQ"
          className="rounded-md object-cover w-full h-full lg:w-1/2 lg:mt-10"
        />
        <p className="p-3 pt-0">
          We understand that the settlement recovery process can be confusing
          and overwhelming. To help you get started, we have compiled a list of
          the most frequently asked questions from our clients:
        </p>
      </div>
      <div className="flex flex-1 flex-col mx-auto mt-10">
        <div className="flex justify-around md:mx-10 lg:mx-24 border-gray-200">
          {tabs.map((tab, index) => (
            <button
              key={index}
              className={`flex-1 py-2 px-4 text-center ${
                activeTab === index
                  ? "border-b-2 border-primary_green-light text-primary_green-light"
                  : "border-b-2 border-transparent text-primary_green-deep"
              }`}
              onClick={() => setActiveTab(index)}
            >
              {tab.name}
            </button>
          ))}
        </div>
        {activeTab == 0 && (
          <div className="p-4">
            <div className="p-4 pt-0 lg:mt-0 flex items-start text-lg ">
              <SettlementRecoveryData customData={faqData} />
            </div>
          </div>
        )}
        {activeTab == 1 && (
          <div className="p-4 pt-7">
            <div className="p-4 pt-0 lg:mt-0 flex items-start text-lg ">
              <SettlementRecoveryData customData={faqVisaData} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FAQs;
